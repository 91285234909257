import React from 'react';

function Slogan() {
  return (
    <div className='slogon-box'>
      <div className='slogan-large-title'>
        Discover great features with our app
      </div>
      <div className='slogan-small-title'>
        Take advantage of our free all-in-one finance and mortgage calculator.
        Simply click the icon on the right and enjoy calculating your needs of
        repayments for:
      </div>
      <div className='slogan-small-title' style={{ display: 'flex' }}>
        <div style={{ width: '40%' }}>
          <div className='slogan-small-title'>- Home Loan</div>
          <div className='slogan-small-title'>- Car Loan</div>
          <div className='slogan-small-title'>- Amortization</div>
        </div>
        <div style={{ width: '60%' }}>
          <div className='slogan-small-title'>- Income Tax</div>
          <div className='slogan-small-title'>- Stamp Duty</div>
          <div className='slogan-small-title'>- Lender Mortgage Insurance</div>
        </div>
      </div>
    </div>
  );
}

export default Slogan;
