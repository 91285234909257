import React from 'react';
import Navigation from './layout/Navigation';
import HomeLoan from './layout/calculators/HomeLoan';
import CarLoan from './layout/calculators/CarLoan';
import LMI from './layout/calculators/LMI';
import IncomeTax from './layout/calculators/IncomeTax';
import StampDuty from './layout/calculators/StampDuty';
import AS from './layout/calculators/AS';
import Privacy from './layout/calculators/Privacy';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import 'antd/dist/antd.css';
import * as firebase from 'firebase/app';
import 'firebase/analytics';

function App() {
  const firebaseConfig = {
    apiKey: 'AIzaSyBDslczkb1ZAvMAiW8xR4N0lX4ovdO2APk',
    authDomain: 'tutube-948f5.firebaseapp.com',
    databaseURL: 'https://tutube-948f5.firebaseio.com',
    projectId: 'tutube-948f5',
    storageBucket: 'tutube-948f5.appspot.com',
    messagingSenderId: '661274035157',
    appId: '1:661274035157:web:95f056ad847abf1acab35c',
    measurementId: 'G-KJNCT5VE7J',
  };
  const fb = firebase.initializeApp(firebaseConfig);
  fb.analytics().logEvent('Tutube loaded');
  console.log('GA v1.0.3');

  return (
    <div className='App'>
      <Router>
        <div className='app-base'>
          <div className='app-size'>
            {/* <Header /> */}
            <Switch>
              <Route exact path='/'>
                <Navigation />
              </Route>
              <Route path='/HomeLoanRepayment'>
                <HomeLoan fb={fb} />
              </Route>
              <Route path='/CarLoanRepayment'>
                <CarLoan fb={fb} />
              </Route>
              <Route path='/AmortizationSchedule'>
                <AS fb={fb} />
              </Route>
              <Route path='/IncomeTax'>
                <IncomeTax fb={fb} />
              </Route>
              <Route path='/StampDuty'>
                <StampDuty fb={fb} />
              </Route>
              <Route path='/LMICalculator'>
                <LMI fb={fb} />
              </Route>
              <Route path='/privacy-policy'>
                <Privacy />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
