import stampDutyNSW from './nsw';
import stampDutyVIC from './vic';
import stampDutyACT from './act';
import stampDutyQLD from './qld';
import stampDutySA from './sa';
import stampDutyWA from './wa';
import stampDutyTAS from './tas';
import stampDutyNT from './nt';

const stampDuty = (
  purchaseValue,
  foreign,
  firstHome,
  vacantLand,
  propertyType,
  children,
  income,
  index
) => {
  switch (index) {
    case 0:
      return stampDutyNSW(
        purchaseValue,
        foreign,
        firstHome,
        vacantLand,
        propertyType
      );
    case 1:
      return stampDutyVIC(
        purchaseValue,
        foreign,
        firstHome,
        vacantLand,
        propertyType
      );
    case 2:
      return stampDutyACT(
        purchaseValue,
        firstHome,
        vacantLand,
        propertyType,
        children,
        income
      );
    case 3:
      return stampDutyQLD(
        purchaseValue,
        foreign,
        firstHome,
        vacantLand,
        propertyType
      );
    case 4:
      return stampDutySA(purchaseValue, foreign);
    case 5:
      return stampDutyWA(
        purchaseValue,
        foreign,
        firstHome,
        vacantLand,
        propertyType
      );
    case 6:
      return stampDutyTAS(
        purchaseValue,
        foreign,
        firstHome,
        vacantLand,
        propertyType
      );
    case 7:
      return stampDutyNT(
        purchaseValue,
        foreign,
        firstHome,
        vacantLand,
        propertyType
      );
    default:
      break;
  }
};

export default stampDuty;
