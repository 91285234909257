import transformCalculation from '../transformCalculation';

const homeLoanRemainingPrincipal = (
  text,
  loanAmount,
  interestRate,
  year,
  repayment
) => {
  let newText = text
    .replace(/loanAmount/g, loanAmount)
    .replace(/interestRate/g, interestRate)
    .replace(/year/g, year)
    .replace(/repayment/g, repayment);
  const result = transformCalculation(newText);
  return result;
};

export default homeLoanRemainingPrincipal;
