import transformCalculation from '../transformCalculation';

const carLoan = (
  text,
  loanAmount,
  residualAmount,
  interestRate,
  loanTerm,
  frequency
) => {
  let newText = text
    .replace(/loanAmount/g, loanAmount)
    .replace(/residualAmount/g, residualAmount)
    .replace(/interestRate/g, interestRate)
    .replace(/loanTerm/g, loanTerm)
    .replace(/frequency/g, frequency);
  const result = transformCalculation(newText);
  return result;
};

export default carLoan;
