import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';

function TutubeDatePicker({ handleValue, defaultValue }) {
  const [date, setDate] = useState(
    String(defaultValue[0]) + '/' + String(defaultValue[1] + 1)
  );
  const monthFormat = 'YYYY/MM';

  return (
    <div className='tutube-input'>
      <DatePicker
        style={{ width: '100%', fontSize: '16px' }}
        defaultValue={moment(date, monthFormat)}
        onChange={handleValue}
        picker='month'
      />
    </div>
  );
}

export default TutubeDatePicker;
