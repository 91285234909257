import transformCalculation from '../transformCalculation';

const stampDutyQLD = (
  purchaseValue,
  foreign,
  firstHome,
  vacantLand,
  propertyType
) => {
  let result = 0;
  let reservedValue = Math.ceil(purchaseValue / 100) * 100;
  console.log(purchaseValue, propertyType, vacantLand);
  if (propertyType) {
    switch (true) {
      case purchaseValue <= 5000:
        result = 0;
        break;
      case 5000 < purchaseValue && purchaseValue <= 75000:
        result = transformCalculation(
          '1.5%*(purchaseValue-5000)'.replace(/purchaseValue/g, reservedValue)
        );
        break;
      case 75000 < purchaseValue && purchaseValue <= 540000:
        result = transformCalculation(
          '1050+3.5%*(purchaseValue-75000)'.replace(
            /purchaseValue/g,
            reservedValue
          )
        );
        break;
      case 540000 < purchaseValue && purchaseValue <= 1000000:
        result = transformCalculation(
          '17325+4.5%*(purchaseValue-540000)'.replace(
            /purchaseValue/g,
            reservedValue
          )
        );
        break;
      case 1000000 < purchaseValue:
        result = transformCalculation(
          '38025+5.75%*(purchaseValue-1000000)'.replace(
            /purchaseValue/g,
            reservedValue
          )
        );
        break;
      default:
        break;
    }
    if (foreign) {
      result += purchaseValue * 0.07;
    }
  } else {
    if (vacantLand === 2) {
      switch (true) {
        case purchaseValue <= 5000:
          result = 0;
          break;
        case 5000 < purchaseValue && purchaseValue <= 75000:
          result = transformCalculation(
            '1.5%*(purchaseValue-5000)'.replace(/purchaseValue/g, reservedValue)
          );
          break;
        case 75000 < purchaseValue && purchaseValue <= 540000:
          result = transformCalculation(
            '1050+3.5%*(purchaseValue-75000)'.replace(
              /purchaseValue/g,
              reservedValue
            )
          );
          break;
        case 540000 < purchaseValue && purchaseValue <= 1000000:
          result = transformCalculation(
            '17325+4.5%*(purchaseValue-540000)'.replace(
              /purchaseValue/g,
              reservedValue
            )
          );
          break;
        case 1000000 < purchaseValue:
          result = transformCalculation(
            '38025+5.75%*(purchaseValue-1000000)'.replace(
              /purchaseValue/g,
              reservedValue
            )
          );
          break;
        default:
          break;
      }
    } else {
      switch (true) {
        case purchaseValue <= 350000:
          result = transformCalculation(
            '1.0%*purchaseValue'.replace(/purchaseValue/g, purchaseValue)
          );
          break;
        case 350001 <= purchaseValue && purchaseValue <= 540000:
          result = transformCalculation(
            '3500+3.5%*(purchaseValue-350000)'.replace(
              /purchaseValue/g,
              purchaseValue
            )
          );
          break;
        case 540001 <= purchaseValue && purchaseValue <= 1000000:
          result = transformCalculation(
            '10150+4.5%*(purchaseValue-540000)'.replace(
              /purchaseValue/g,
              purchaseValue
            )
          );
          break;
        case 1000000 < purchaseValue:
          result = transformCalculation(
            '30850+5.75%*(purchaseValue-1000000)'.replace(
              /purchaseValue/g,
              purchaseValue
            )
          );
          break;
        default:
          break;
      }
    }
    if (foreign) {
      result += purchaseValue * 0.07;
    } else {
      if (firstHome) {
        if (vacantLand === 2) {
          switch (true) {
            case purchaseValue < 250000:
              result = 0;
              break;
            case 250000 <= purchaseValue && purchaseValue < 260000:
              result -= 7175;
              break;
            case 260000 <= purchaseValue && purchaseValue < 270000:
              result -= 6700;
              break;
            case 270000 <= purchaseValue && purchaseValue < 280000:
              result -= 6225;
              break;
            case 280000 <= purchaseValue && purchaseValue < 290000:
              result -= 5750;
              break;
            case 290000 <= purchaseValue && purchaseValue < 300000:
              result -= 5275;
              break;
            case 300000 <= purchaseValue && purchaseValue < 310000:
              result -= 4800;
              break;
            case 310000 <= purchaseValue && purchaseValue < 320000:
              result -= 4325;
              break;
            case 320000 <= purchaseValue && purchaseValue < 330000:
              result -= 3850;
              break;
            case 330000 <= purchaseValue && purchaseValue < 340000:
              result -= 3375;
              break;
            case 340000 <= purchaseValue && purchaseValue < 350000:
              result -= 2900;
              break;
            case 350000 <= purchaseValue && purchaseValue < 360000:
              result -= 2425;
              break;
            case 360000 <= purchaseValue && purchaseValue < 370000:
              result -= 1950;
              break;
            case 370000 <= purchaseValue && purchaseValue < 380000:
              result -= 1475;
              break;
            case 380000 <= purchaseValue && purchaseValue < 390000:
              result -= 1000;
              break;
            case 390000 <= purchaseValue && purchaseValue < 400000:
              result -= 525;
              break;
            default:
              break;
          }
        } else {
          switch (true) {
            case purchaseValue < 505000:
              result -= 8750;
              break;
            case 505000 <= purchaseValue && purchaseValue < 510000:
              result -= 7875;
              break;
            case 510000 <= purchaseValue && purchaseValue < 515000:
              result -= 7000;
              break;
            case 515000 <= purchaseValue && purchaseValue < 520000:
              result -= 6125;
              break;
            case 520000 <= purchaseValue && purchaseValue < 525000:
              result -= 5250;
              break;
            case 525000 <= purchaseValue && purchaseValue < 530000:
              result -= 4375;
              break;
            case 530000 <= purchaseValue && purchaseValue < 535000:
              result -= 3500;
              break;
            case 535000 <= purchaseValue && purchaseValue < 540000:
              result -= 2625;
              break;
            case 540000 <= purchaseValue && purchaseValue < 545000:
              result -= 1750;
              break;
            case 545000 <= purchaseValue && purchaseValue < 550000:
              result -= 875;
              break;
            default:
              break;
          }
        }
      }
    }
  }
  if (result <= 0) {
    result = 0;
  }

  let mortgage = 192;
  let transferFee =
    purchaseValue < 180000
      ? 192
      : 192 + ((purchaseValue - 180000) / 10000) * 36;

  let outcome = {
    result: result,
    mortgage: mortgage,
    transferFee: transferFee,
    total: result + mortgage + transferFee,
  };

  return outcome;
};

export default stampDutyQLD;
