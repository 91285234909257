import React, { useState, useEffect, useCallback, Fragment } from 'react';
import CalculatorNav from '../../components/CalculatorNav';
import CalculatorBox from '../../components/CalculatorBox';
import CalculatorBoxRow from '../../components/CalculatorBoxRow';
import HalfBox from '../../components/HalfBox';
import WhiteBg from '../../components/WhiteBg';
import InputRow from '../../components/InputRow';
import ResultRow from '../../components/ResultRow';
import ButtonGroup from '../../components/ButtonGroup';
import stampDuty from '../../util/stamp-duty/stampDuty';
import { Typography } from 'antd';

function LMI({ fb }) {
  /* --------------------------------- States -----------------å---------------- */

  const [purchaseValue, setPurchaseValue] = useState(600000);
  const [foreign, setForeign] = useState(0);
  const [firstHome, setFirstHome] = useState(0);
  const [vacantLand, setVacantLand] = useState(0);
  const [propertyType, setPropertyType] = useState(0);
  const [children, setChildren] = useState(0);
  const [income, setIncome] = useState(0);
  const [stateSelected, setStateSelected] = useState(0);
  const [result, setResult] = useState(0);
  const [mortgage, setMortgage] = useState(0);
  const [transferFee, setTransferFee] = useState(0);
  const [total, setTotal] = useState(0);
  const [inputs, setInputs] = useState(null);

  /* --------------------------------- Static --------------------------------- */

  const { Title } = Typography;
  const KEY1 = 11;
  const KEY2 = 22;
  const KEY3 = 33;
  const KEY4 = 44;
  const TITLE1 = 'Property Value';
  const TITLE2 = 'Total Income';
  const TITLE3 = 'Number of Children';
  const MONEY = 'money';
  const RESTITLE1 = 'Stamp Duty';
  const RESTITLE2 = 'Transfer Fee';
  const RESTITLE3 = 'Mortgage Registration Fee';
  const RESTITLE4 = 'Total';
  const [RANGE1] = useState([1, 100000000]);
  const [RANGE2] = useState([1, 1000000000000000]);
  const STATE = 'state';
  const [BUTTONS] = useState(['No', 'Yes']);
  const [PROPERTIES] = useState(['Owner Occupied', 'Investment']);
  const [VACANTLANDTYPES] = useState([
    'Est. Home',
    'Off The Plan',
    'Vacant Land',
  ]);
  const [STATES] = useState([
    'NSW',
    'VIC',
    'ACT',
    'QLD',
    'SA',
    'WA',
    'TAS',
    'NT',
  ]);

  /* -------------------------------- Functions ------------------------------- */

  const handlePurchaseValue = useCallback(
    (newValue) => {
      setPurchaseValue(newValue);
    },
    [setPurchaseValue]
  );

  const handleForeign = useCallback(
    (newValue) => {
      setForeign(newValue);
    },
    [setForeign]
  );

  const handleFirstHome = useCallback(
    (newIndex) => {
      setFirstHome(newIndex);
    },
    [setFirstHome]
  );

  const handleVacantLand = useCallback(
    (newIndex) => {
      setVacantLand(newIndex);
    },
    [setVacantLand]
  );

  const handlePropertyType = useCallback(
    (newIndex) => {
      setPropertyType(newIndex);
    },
    [setPropertyType]
  );

  const handleChildren = useCallback(
    (newIndex) => {
      setChildren(parseInt(newIndex));
    },
    [setChildren]
  );

  const handleIncome = useCallback(
    (newIndex) => {
      setIncome(newIndex);
    },
    [setIncome]
  );

  const handleStateSelected = useCallback(
    (newIndex) => {
      setStateSelected(newIndex);
    },
    [setStateSelected]
  );

  /* ------------------------------- Calculation ------------------------------ */

  useEffect(() => {
    let sd = stampDuty(
      purchaseValue,
      foreign,
      firstHome,
      vacantLand,
      propertyType,
      children,
      income,
      stateSelected
    );
    setResult(sd['result']);
    setMortgage(sd['mortgage']);
    setTransferFee(sd['transferFee']);
    setTotal(sd['total']);
  }, [
    purchaseValue,
    foreign,
    firstHome,
    vacantLand,
    propertyType,
    children,
    income,
    stateSelected,
  ]);

  useEffect(() => {
    if (stateSelected === 2) {
      setInputs([
        <InputRow
          key={KEY1}
          title={TITLE1}
          type={MONEY}
          defaultValue={purchaseValue}
          handleValue={handlePurchaseValue}
          range={RANGE1}
        />,
        <InputRow
          key={KEY2}
          title={TITLE2}
          type={MONEY}
          defaultValue={income}
          handleValue={handleIncome}
          range={RANGE2}
        />,
        <InputRow
          key={KEY3}
          title={TITLE3}
          type={MONEY}
          defaultValue={children}
          handleValue={handleChildren}
          range={RANGE1}
          select
        />,
      ]);
    } else {
      setInputs([
        <InputRow
          key={KEY1}
          title={TITLE1}
          type={MONEY}
          defaultValue={purchaseValue}
          handleValue={handlePurchaseValue}
          range={RANGE1}
        />,
      ]);
    }
  }, [stateSelected]);

  useEffect(() => {
    fb.analytics().logEvent('Stamp Duty Calculator Loaded');
    console.log('Stamp Duty Calculator Loaded');
  }, [fb]);

  return (
    <Fragment>
      <div className='landing-bg-dark-blue'>
        <div className='landing-circle'></div>
        <div className='landing-bg-mask'></div>
      </div>
      <CalculatorNav />
      <CalculatorBox>
        <CalculatorBoxRow>
          <HalfBox>
            <Title level={3}>Stamp Duty Calculator</Title>
            <WhiteBg>{inputs}</WhiteBg>
            <div className='m-bottom'>
              <ButtonGroup
                titles={STATES}
                handleIndex={handleStateSelected}
                variant={STATE}
              />
            </div>
            <div className='m-bottom'>
              <ButtonGroup
                titles={PROPERTIES}
                handleIndex={handlePropertyType}
              />
            </div>
            <div className='m-bottom'>
              <ButtonGroup
                titles={VACANTLANDTYPES}
                handleIndex={handleVacantLand}
              />
            </div>
            <div className='m-bottom btn-group-row'>
              <div className='item-title black flex-1'>First Home Buyer</div>
              <div className='flex-1'>
                <ButtonGroup titles={BUTTONS} handleIndex={handleFirstHome} />
              </div>
            </div>
            <div className='m-bottom btn-group-row'>
              <div className='item-title black flex-1'>Non-Resident</div>
              <div className='flex-1'>
                <ButtonGroup titles={BUTTONS} handleIndex={handleForeign} />
              </div>
            </div>
          </HalfBox>
          <HalfBox>
            <Title level={3}>Results</Title>
            <WhiteBg>
              <ResultRow key={KEY1} title={RESTITLE1} value={result} />
              <ResultRow key={KEY2} title={RESTITLE2} value={transferFee} />
              <ResultRow key={KEY3} title={RESTITLE3} value={mortgage} />
              <ResultRow key={KEY4} title={RESTITLE4} value={total} />
            </WhiteBg>
          </HalfBox>
        </CalculatorBoxRow>
      </CalculatorBox>
    </Fragment>
  );
}

export default LMI;
