import React, { useEffect, useState } from 'react';
import { Chart } from '@antv/g2';

function AreaChart({ data, max, legends }) {
  const [chart, setChart] = useState(null);
  const [formatter] = useState(new Intl.NumberFormat());
  const getUpperAmount = (amount) => {
    let counter = '1';
    while (amount >= 10) {
      amount = amount / 10;
      counter += 0;
    }
    if (amount <= 1.5) {
      amount = parseInt(amount) + 0.5;
    } else {
      amount = parseInt(amount) + 1;
    }
    return amount * counter;
  };

  useEffect(() => {
    if (chart === null) {
      const chart = new Chart({
        container: 'c1',
        renderer: 'svg',
        autoFit: true,
      });
      chart
        .line()
        .position('year*amount')
        .color('type', (type) => {
          if (type === legends[0]) {
            return '#47bffc';
          } else {
            return '#7591fc';
          }
        })
        .size(2);
      chart.data(data);
      chart.legend(false);
      chart.tooltip({
        showCrosshairs: true,
        shared: true,
      });
      chart.render();
      setChart(chart);
    } else {
      let upperAmount = getUpperAmount(max.maxAmount);
      let upperYear = max.maxYear;
      chart.changeData(data);
      if (data.length > 0) {
        chart.scale('amount', {
          min: 0,
          max: upperAmount,
          formatter: (val) => '$' + formatter.format(val),
        });
        chart.scale('year', {
          min: 0,
          max: upperYear,
        });
      }
      chart.render();
    }
  }, [data, chart, formatter, legends, max]);
  return (
    <div className='chart-box'>
      <div id='c1' className='chart-container'></div>
      <div className='chart-declaimer'>
        <div className='declaimer'>
          * Principal + Interest chart available only
        </div>
        <div className='legend-box'>
          <div className='legend'>
            <div className='legend-1' />
            Total Balance
          </div>
          <div className='legend'>
            <div className='legend-2' />
            Loan Principal
          </div>
        </div>
      </div>
      <div className='label-year'>Years</div>
    </div>
  );
}

export default AreaChart;
