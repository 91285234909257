import React, { useState } from 'react';
import { Table } from 'antd';

function TutubeTable({ table }) {
  const [right] = useState({ textAlign: 'right' });
  const columns = [
    {
      title: '',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Weekly',
      dataIndex: 'Weekly',
      key: 'Weekly',
      align: 'right',
    },
    {
      title: 'Fortnightly',
      dataIndex: 'Fortnightly',
      key: 'Fortnightly',
      align: 'right',
    },
    {
      title: 'Monthly',
      dataIndex: 'Monthly',
      key: 'Monthly',
      align: 'right',
    },
    {
      title: 'Annually',
      dataIndex: 'Annually',
      key: 'Annually',
      align: 'right',
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={table ? table[0] : null}
        pagination={false}
        summary={() => {
          if (table) {
            return (
              <Table.Summary.Row style={{ backgroundColor: '#fafafa' }}>
                <Table.Summary.Cell>
                  <strong>{table[1][0]}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <div style={right}>
                    <strong>{table[1][1]}</strong>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <div style={right}>
                    <strong>{table[1][2]}</strong>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  <div style={right}>
                    <strong>{table[1][3]}</strong>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <div style={right}>
                    <strong>{table[1][4]}</strong>
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }
        }}
      />
    </div>
  );
}

export default TutubeTable;
