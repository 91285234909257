import transformCalculation from '../transformCalculation';

const switchFrequency = (num, frequency) => {
  switch (frequency) {
    case 0:
      return num / 52;
    case 1:
      return num / 26;
    case 2:
      return num / 12;
    case 3:
      return num;
    default:
      break;
  }
};

const getOffset = (income, tax) => {
  let offset = 0;
  switch (true) {
    case income < 37000:
      offset = 255;
      break;
    case 37000 <= income && income < 48000:
      offset = 255 + 0.075 * (income - 37000);
      break;
    case 48000 <= income && income < 90000:
      offset = 1080;
      break;
    case 90000 <= income && income < 126000:
      offset = 1080 - 0.03 * (income - 90000);
      break;
    default:
      offset = 0;
      break;
  }
  switch (true) {
    case income < 21882:
      return tax;
    case 21882 <= income && income < 37000:
      return offset + 445;
    case 37000 <= income && income < 66667:
      return offset + 445 - 0.015 * (income - 37000);
    default:
      return offset;
  }
};

const incomeTax = (income, frequency, foreign) => {
  let tax = 0;

  if (foreign === 0) {
    switch (true) {
      case income <= 18200:
        tax = 0;
        break;
      case 18200 < income && income <= 37000:
        tax = transformCalculation(
          '19%*(income-18200)'.replace(/income/g, income)
        );
        break;
      case 37000 < income && income <= 90000:
        tax = transformCalculation(
          '3572+32.5%*(income-37000)'.replace(/income/g, income)
        );
        break;
      case 90000 < income && income <= 180000:
        tax = transformCalculation(
          '20797+37%*(income-90000)'.replace(/income/g, income)
        );
        break;
      default:
        tax = transformCalculation(
          '54097+45%*(income-180000)'.replace(/income/g, income)
        );
        break;
    }
  } else {
    switch (true) {
      // 没有offset
      case income <= 90000:
        tax = transformCalculation('32.5%*income'.replace(/income/g, income));
        break;
      case 90000 < income && income <= 180000:
        tax = transformCalculation(
          '29250+37%*(income-90000)'.replace(/income/g, income)
        );
        break;
      default:
        tax = transformCalculation(
          '62550+45%*(income-180000)'.replace(/income/g, income)
        );
        break;
    }
  }

  if (tax <= 0) {
    tax = 0;
  }
  let totalTax = 0;

  if (frequency !== 3) {
    let paygBrackets = [
      { income: 422, a: 0.19, b: 67.4635 },
      { income: 528, a: 0.29, b: 109.7327 },
      { income: 711, a: 0.21, b: 67.4635 },
      { income: 1282, a: 0.3477, b: 165.4423 },
      { income: 1730, a: 0.345, b: 161.9808 },
      { income: 3461, a: 0.39, b: 239.8654 },
      { income: 99999999999999, a: 0.47, b: 516.7885 },
    ];
    let a = 0;
    let b = 0;
    let x = 0;

    switch (frequency) {
      case 0:
        x = parseInt(income / 52) + 0.99;
        for (let paygBracket of paygBrackets) {
          if (x < paygBracket.income) {
            a = paygBracket.a;
            b = paygBracket.b;
            break;
          }
        }
        totalTax = parseFloat((x * a - b).toFixed(0));
        break;
      case 1:
        x = parseInt(income / 52) + 0.99;
        for (let paygBracket of paygBrackets) {
          if (x < paygBracket.income) {
            a = paygBracket.a;
            b = paygBracket.b;
            break;
          }
        }
        totalTax = parseFloat((x * a - b).toFixed(0)) * 2;
        break;
      case 2:
        x = income / 12;
        // var cents = Math.round(100 * (x - Math.floor(x)));
        // if (cents == 33) x += 0.01;
        x = parseInt((x * 3) / 13) + 0.99;
        for (let paygBracket of paygBrackets) {
          if (x < paygBracket.income) {
            a = paygBracket.a;
            b = paygBracket.b;
            break;
          }
        }
        // console.log(parseFloat((x * a - b).toFixed(0)));
        totalTax = Math.round(parseFloat(((x * a - b).toFixed(0) * 13) / 3));
        break;
      default:
        break;
    }
  }

  let superannuation = income * 0.095;
  let medicalLevy = 0;
  if (foreign === 0) {
    medicalLevy = income * 0.02;
    if (22399 < income && income <= 27997) {
      medicalLevy = (income - 22398) * 0.1;
    } else if (income <= 22398) {
      medicalLevy = 0;
    }
  }

  let offset = 0;
  if (frequency === 3 && foreign === 0) {
    offset = getOffset(income, tax);
    tax = Math.round(tax) - offset;
  }

  tax = Math.round(switchFrequency(tax, frequency) + medicalLevy);
  // console.log(income - tax - medicalLevy);
  let netIncome = income - tax;

  let outcome = {
    netIncome:
      frequency === 3
        ? switchFrequency(netIncome, frequency)
        : switchFrequency(income, frequency) - totalTax,
    tax: frequency === 3 ? tax : totalTax,
    superannuation: switchFrequency(superannuation, frequency),
    medicalLevy: switchFrequency(medicalLevy, frequency),
  };

  return outcome;
};

export default incomeTax;
