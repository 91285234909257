import React from 'react';
import TutubeOutput from './TutubeOutput';

function ResultRow({ title, value, rate }) {
  return (
    <div className='row'>
      <TutubeOutput title={title} value={value} rate={rate} />
    </div>
  );
}

export default ResultRow;
