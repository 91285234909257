import React, { useState, useEffect, useCallback, Fragment } from 'react';
import CalculatorNav from '../../components/CalculatorNav';
import CalculatorBox from '../../components/CalculatorBox';
import CalculatorBoxRow from '../../components/CalculatorBoxRow';
import HalfBox from '../../components/HalfBox';
import FullBox from '../../components/FullBox';
import WhiteBg from '../../components/WhiteBg';
import InputRow from '../../components/InputRow';
import ResultRow from '../../components/ResultRow';
import TutubeTable from '../../components/TutubeTable';
import as from '../../util/as/as';
import { Typography } from 'antd';

function Income({ fb }) {
  /* --------------------------------- States -----------------å---------------- */

  const [loanAmount, setLoanAmount] = useState(80000);
  const [loanTerm, setLoanTerm] = useState(2);
  const [interestRate, setInterestRate] = useState(0.04);
  const [balloon, setBalloon] = useState(0.25);
  const [now] = useState(new Date(Date.now()));
  const [year, setYear] = useState(now.getFullYear());
  const [month, setMonth] = useState(now.getMonth());
  const [date] = useState([now.getFullYear(), now.getMonth()]);
  const [payment, setPayment] = useState(0);
  const [table, setTable] = useState([]);

  /* --------------------------------- Static --------------------------------- */

  const { Title } = Typography;
  const KEY1 = 11;
  const KEY2 = 22;
  const KEY3 = 33;
  const KEY4 = 44;
  const KEY5 = 55;
  const TITLE1 = 'Loan Amount';
  const TITLE2 = 'Loan Term';
  const TITLE3 = 'Interest Rate';
  const TITLE4 = 'Balloon';
  const TITLE5 = 'Start Date';
  const RESTITLE1 = 'Monthly Repayment';
  const MONEY = 'money';
  const RATE = 'rate';
  const YEAR = 'year';
  const [RANGE1] = useState([1, 10000000]);
  const [RANGE2] = useState([1, 50]);
  const [RANGE3] = useState([0, 100]);
  const [RANGE4] = useState([0, 50]);

  /* -------------------------------- Functions ------------------------------- */

  const handleLoanAmount = useCallback(
    (newValue) => {
      setLoanAmount(newValue);
    },
    [setLoanAmount]
  );

  const handleInterestRate = useCallback(
    (newValue) => {
      setInterestRate(newValue);
    },
    [setInterestRate]
  );

  const handleLoanTerm = useCallback(
    (newValue) => {
      setLoanTerm(newValue);
    },
    [setLoanTerm]
  );

  const handleBalloon = useCallback(
    (newValue) => {
      setBalloon(newValue);
    },
    [setBalloon]
  );

  const handleDate = useCallback(
    (date) => {
      setMonth(date.month());
      setYear(date.year());
    },
    [setMonth, setYear]
  );

  /* ------------------------------- Calculation ------------------------------ */

  useEffect(() => {
    let table = as(loanAmount, interestRate, loanTerm, balloon, month, year);
    setPayment(table.payment);
    setTable(table.table);
  }, [loanAmount, interestRate, loanTerm, balloon, month, year]);

  useEffect(() => {
    fb.analytics().logEvent('Amortization Schedule Loaded');
    console.log('Amortization Schedule Loaded');
  }, [fb]);

  return (
    <Fragment>
      <div className='landing-bg-dark-blue'>
        <div className='landing-circle'></div>
        <div className='landing-bg-mask'></div>
      </div>
      <CalculatorNav />
      <CalculatorBox>
        <CalculatorBoxRow>
          <HalfBox>
            <Title level={3}>Amortization Schedule</Title>
            <WhiteBg>
              <InputRow
                key={KEY1}
                title={TITLE1}
                type={MONEY}
                defaultValue={loanAmount}
                handleValue={handleLoanAmount}
                range={RANGE1}
              />
              <InputRow
                key={KEY2}
                title={TITLE2}
                type={YEAR}
                defaultValue={loanTerm}
                handleValue={handleLoanTerm}
                range={RANGE2}
              />
              <InputRow
                key={KEY3}
                title={TITLE3}
                type={RATE}
                defaultValue={interestRate}
                handleValue={handleInterestRate}
                range={RANGE3}
              />
              <InputRow
                key={KEY4}
                title={TITLE4}
                type={RATE}
                defaultValue={balloon}
                handleValue={handleBalloon}
                range={RANGE4}
              />
              <InputRow
                key={KEY5}
                title={TITLE5}
                defaultValue={date}
                handleValue={handleDate}
                date
              />
            </WhiteBg>
          </HalfBox>
          <HalfBox>
            <Title level={3}>Results</Title>
            <WhiteBg>
              <ResultRow key={KEY1} title={RESTITLE1} value={payment} />
            </WhiteBg>
          </HalfBox>
        </CalculatorBoxRow>
        <CalculatorBoxRow>
          <FullBox>
            <WhiteBg>
              <TutubeTable table={table} />
            </WhiteBg>
          </FullBox>
        </CalculatorBoxRow>
      </CalculatorBox>
    </Fragment>
  );
}

export default Income;
