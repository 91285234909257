import React from 'react';
import Header from './Header';
import Slogan from './Slogan';
import Navbtn from '../components/NavBtn';
import Footer from './Footer';
import { Link } from 'react-router-dom';

function Navigation() {
  return (
    <div className='nav-box'>
      <div className='landing-bg-dark-blue'>
        <div className='landing-circle'></div>
        <div className='landing-bg-mask'></div>
      </div>
      <div className='app-base'>
        <div className='app-size'>
          <Header />
          <Slogan />
          <div className='icons-outer-box'>
            <div className='icon-box'>
              <Link to='/HomeLoanRepayment'>
                <Navbtn obj={'home'} />
              </Link>
              <Link to='/CarLoanRepayment'>
                <Navbtn obj={'car'} />
              </Link>
              <Link to='/AmortizationSchedule'>
                <Navbtn obj={'as'} />
              </Link>
            </div>
            <div className='icon-box m-r'>
              <Link to='/IncomeTax'>
                <Navbtn obj={'income'} />
              </Link>
              <Link to='/StampDuty'>
                <Navbtn obj={'stamp'} />
              </Link>
              <Link to='/LMICalculator'>
                <Navbtn obj={'lmi'} />
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Navigation;
