import React from 'react';

function Privacy() {
  return (
    <div>
      Privacy Policy Revision Date: Wed May 19, 2020 1. Mortgage calculator AU
      by Tutube provides the user access to various finance calculator for
      Australia only. 2. Whilst every effort has been made to ensure the
      accuracy of the calculators, the results should be used as an indication
      only. They are either a quote or a pre-qualification for any type of loan.
      Information to be collected 3. We do not collect any personal information
      nor seek to collect sensitive information, including your health, racial
      or ethnic origin, political opinions, membership of a political
      association, professional or trade association or trade union, religious
      beliefs or affiliations, philosophical beliefs, sexual orientation or
      practices, criminal record, biometric information that is to be used for
      certain purposes, or biometric templates, unless it is necessary to carry
      out our services to you. In the event that we have to collect the
      aforesaid sensitive information, we shall strictly adhere to the
      Australian Privacy Principles. Cookies and beacons 4. We may use cookies
      on our Site/APP from time to time. Cookies are text files placed in your
      computer’s browser to store your preferences. Cookies, by themselves, do
      not tell us your email address or other personally identifiable
      information. However, they do allow third parties, such as Google and
      Facebook, to cause our advertisements to appear on your social media and
      online media feeds as part of our retargeting campaigns. If and when you
      choose to provide our Site with personal information, this information may
      be linked to the data stored in the cookie. 5. We may use web beacons on
      our Site from time to time. Web beacons (also known as Clear GIFs) are
      small pieces of code placed on a web page to monitor the visitor’s
      behaviour and collect data about the visitor’s viewing of a web page. For
      example, web beacons can be used to count the users who visit a web page
      or to deliver a cookie to the browser of a visitor viewing that page. 6.
      We may use Google Analytics to collect and process data. To find out how
      Google uses data when you use third party websites or applications, please
      see www.google.com/policies/privacy/partners/ or any other URL Google may
      use from time to time. Security of personal information 7. Tutube
      endeavours to maintain robust security measures to protect any loss,
      misuse or unauthorised alteration of information stored on our database.
      We exercise utmost care in providing secure transmission of information
      between your computer, smart devices such as smartphones and tablets,
      and/or other electronic devices and our servers. Notwithstanding this, we
      do not guarantee that any security of information that is submitted via
      the internet. Privacy complaints 8. We endeavour to promptly respond to
      any questions or complaints you may have regarding our privacy processes.
      If at any time you may have a complaint about our collection, handling,
      use or disclosure of information, please write to us at: Tutube Pty Ltd
      Leve 4 383 George Street Sydney NSW 2000 Email: developer@tutube.com.au
      Amendment of policy 9. We reserve our rights to amend this policy from
      time to time without prior notice or consent, unless otherwise we are
      required to do so. In the event that we make any material changes to this
      policy, we will notify you by email and change the revision date at the
      top of this policy. We advise users to review this page from time to time
      in order to stay up-to-date regarding changes of this policy.
    </div>
  );
}

export default Privacy;
