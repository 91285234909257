import React, { useEffect, useState } from 'react';
import { Divider } from 'antd';

function WhiteBg({ children }) {
  const [state, setstate] = useState([]);
  useEffect(() => {
    let newChildren = [];
    if (Array.isArray(children)) {
      for (let i in children) {
        newChildren.push(children[i]);
        parseInt(i) !== children.length - 1 &&
          newChildren.push(<Divider key={i} className='divider' />);
      }
    } else {
      newChildren = children;
    }
    setstate(newChildren);
  }, [children]);
  return <div className='white-bg shadow radius m-bottom'>{state}</div>;
}

export default WhiteBg;
