import React from 'react';
import { Statistic } from 'antd';

function TutubeOutput({ title, value, rate }) {
  return (
    <div className='tutube-output' style={{ height: '63.3px' }}>
      <div className='item-title black'>{title}:</div>
      <Statistic
        prefix={rate ? '' : '$'}
        suffix={rate ? '%' : ''}
        value={rate ? (value * 100).toFixed(2) : value.toFixed(2)}
      />
    </div>
  );
}

export default TutubeOutput;
