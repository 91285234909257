import React, { Fragment } from 'react';

function CalculatorBox({ children, disclaimer, lmi }) {
  return (
    <Fragment>
      <div className='calculator-box'>
        <div className='calculator-inner-box'>{children}</div>
      </div>
      <div
        className='footer-2'
        style={
          lmi
            ? { flexDirection: 'column', marginTop: '90px' }
            : { flexDirection: 'column' }
        }
      >
        <div className='footer-left'>
          <a href='https://apps.apple.com/au/app/mortgage-calculator-au/id1512133933'>
            <div className='apple'></div>
          </a>
          <a href='https://play.google.com/store/apps/details?id=com.tutube.www'>
            <div className='google' />
          </a>
        </div>
        {disclaimer ? (
          <div
            className='disclaimer'
            style={{ marginTop: '16px', width: '100%' }}
          >
            Disclaimer:
            <div
              style={{
                marginLeft: '10px',
                fontWeight: '200',
                fontSize: '12px',
                textAlign: 'justify',
                marginTop: '2.5px',
              }}
            >
              {disclaimer === 'homeloan' ? (
                <div>
                  The results provided by the calculator are to be taken as a
                  reference or guide only. Results only rely on the information
                  provided and the assumptions that have been pre-set. There are
                  also a number of other factors that influence how much lenders
                  mortgage insurance can cost a buyer, such as their borrowing
                  power, property type, and financial situation,which are also
                  not considered by the calculator.
                </div>
              ) : (
                <div>
                  The results provided by the calculator are to be taken as a
                  reference or guide only. The calculation on the medical levy
                  assumes that you are singleand with no dependant and there are
                  no other levies. For full information on other factors that
                  affect your tax, please visit theAustralian Tax Office full
                  website.
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
}

export default CalculatorBox;
