import React, { useState, useEffect, useCallback, Fragment } from 'react';
import CalculatorNav from '../../components/CalculatorNav';
import CalculatorBox from '../../components/CalculatorBox';
import CalculatorBoxRow from '../../components/CalculatorBoxRow';
import HalfBox from '../../components/HalfBox';
import WhiteBg from '../../components/WhiteBg';
import InputRow from '../../components/InputRow';
import ResultRow from '../../components/ResultRow';
import ButtonGroup from '../../components/ButtonGroup';
import lmi from '../../util/lmi/lmi';
import { Typography } from 'antd';

function LMI({ fb }) {
  /* --------------------------------- States -----------------å---------------- */

  const [propertyValue, setPropertyValue] = useState(100000);
  const [loanAmount, setLoanAmount] = useState(90000);
  const [index, setIndex] = useState(0);
  const [stateSelected, setStateSelected] = useState(0);
  const [stampDuty, setStampDuty] = useState(0);
  const [lvr, setLvr] = useState(0);

  /* --------------------------------- Static --------------------------------- */

  const { Title } = Typography;
  const KEY1 = 11;
  const KEY2 = 22;
  const TITLE1 = 'Property Value';
  const TITLE2 = 'Loan Amount';
  const MONEY = 'money';
  const RESTITLE1 = 'LVR';
  const RESTITLE2 = 'Lender Mortgage Insurance';
  const [RANGE1] = useState([1, 100000000]);
  const STATE = 'state';
  const [TITLES] = useState(['Owner Occupied', 'Investment']);
  const [STATES] = useState([
    'NSW',
    'VIC',
    'ACT',
    'QLD',
    'SA',
    'WA',
    'TAS',
    'NT',
  ]);

  /* -------------------------------- Functions ------------------------------- */

  const handleLoanAmount = useCallback(
    (newValue) => {
      setLoanAmount(newValue);
    },
    [setLoanAmount]
  );

  const handlePropertyValue = useCallback(
    (newValue) => {
      setPropertyValue(newValue);
    },
    [setPropertyValue]
  );

  const handleIndex = useCallback(
    (newIndex) => {
      setIndex(newIndex);
    },
    [setIndex]
  );

  const handleStateSelected = useCallback(
    (newIndex) => {
      setStateSelected(newIndex);
    },
    [setStateSelected]
  );

  /* ------------------------------- Calculation ------------------------------ */

  useEffect(() => {
    let sl = lmi(
      parseInt(propertyValue),
      parseInt(loanAmount),
      index,
      stateSelected
    );
    setStampDuty(sl['stampDuty']);
    setLvr(sl['LVR']);
  }, [propertyValue, loanAmount, index, stateSelected]);

  useEffect(() => {
    fb.analytics().logEvent('LMI Calculator Loaded');
    console.log('LMI Calculator Loaded');
  }, [fb]);

  return (
    <Fragment>
      <div className='landing-bg-dark-blue'>
        <div className='landing-circle'></div>
        <div className='landing-bg-mask'></div>
      </div>
      <CalculatorNav />
      <CalculatorBox lmi>
        <CalculatorBoxRow>
          <HalfBox>
            <Title level={3}>LMI Calculator</Title>
            <WhiteBg>
              <InputRow
                key={KEY1}
                title={TITLE1}
                type={MONEY}
                defaultValue={propertyValue}
                handleValue={handlePropertyValue}
                range={RANGE1}
              />
              <InputRow
                key={KEY2}
                title={TITLE2}
                type={MONEY}
                defaultValue={loanAmount}
                handleValue={handleLoanAmount}
                range={[1, propertyValue]}
              />
            </WhiteBg>
            <ButtonGroup
              titles={STATES}
              handleIndex={handleStateSelected}
              variant={STATE}
            />
            <div className='m-bottom'></div>
            <ButtonGroup titles={TITLES} handleIndex={handleIndex} />
          </HalfBox>
          <HalfBox>
            <Title level={3}>Results</Title>
            <WhiteBg>
              <ResultRow key={KEY1} title={RESTITLE1} value={lvr} rate />
              <ResultRow key={KEY2} title={RESTITLE2} value={stampDuty} />
            </WhiteBg>
          </HalfBox>
        </CalculatorBoxRow>
      </CalculatorBox>
    </Fragment>
  );
}

export default LMI;
