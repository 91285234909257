import React from 'react';

function NavBtn({ obj }) {
  const objects = {
    home: {
      image: require('../assets/home.png'),
      imageChosen: require('../assets/home-chosen.png'),
      title: 'Home Loan\nRepayment',
    },
    lmi: {
      image: require('../assets/lmi.png'),
      imageChosen: require('../assets/lmi-chosen.png'),
      title: 'LMI Calculator',
    },
    car: {
      image: require('../assets/car.png'),
      imageChosen: require('../assets/car-chosen.png'),
      title: 'Car Loan\nRepayment',
    },
    as: {
      image: require('../assets/as.png'),
      imageChosen: require('../assets/as-chosen.png'),
      title: 'Amortization\nSchedule',
    },
    stamp: {
      image: require('../assets/stamp.png'),
      imageChosen: require('../assets/stamp-chosen.png'),
      title: 'Stamp Duty',
    },
    income: {
      image: require('../assets/income.png'),
      imageChosen: require('../assets/income-chosen.png'),
      title: 'Income Tax',
    },
  };

  return (
    <div className='nav-btn-box'>
      <div className='nav-btn-bg'>
        <img
          className='nav-btn-icon'
          src={objects[obj].imageChosen}
          alt={obj}
        ></img>
        <div className='nav-btn-title white'>{objects[obj].title}</div>
      </div>
      <div className='nav-btn'>
        <img className='nav-btn-icon' src={objects[obj].image} alt={obj}></img>
        <div className='nav-btn-title'>{objects[obj].title}</div>
      </div>
    </div>
  );
}

export default NavBtn;
