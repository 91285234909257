import React, { useEffect, useState } from 'react';

function ButtonGroup({ titles, handleIndex, variant, defaultValue = 0 }) {
  /* ----------------------------------- UI ----------------------------------- */

  const [buttons, setButtons] = useState([]);
  const [highlightBgs, setHighlightBgs] = useState([]);
  const [highlight, setHighlight] = useState(0);
  const [btnGroupBox, setBtnGroupBox] = useState('btn-group-box-lg');
  const [btnGroup, setBtnGroup] = useState('btn-group-lg radius');

  useEffect(() => {
    setHighlight(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    const tempBtns = [];
    const tempBgs = [];
    for (let i in titles) {
      i = parseInt(i);

      // Button Groups
      let btnClassName =
        variant === 'state'
          ? 'btn-group-btn-sm radius'
          : 'btn-group-btn radius';
      highlight === i &&
        (btnClassName =
          variant === 'state'
            ? 'btn-group-btn-sm radius white shadow'
            : 'btn-group-btn radius white shadow');
      if (variant === 'bookmark') {
        btnClassName += ' half-radius';
      }
      tempBtns.push(
        <button
          key={i}
          type='link'
          className={btnClassName}
          onClick={() => {
            setHighlight(i);
            handleIndex(i);
          }}
        >
          {titles[i]}
        </button>
      );

      // Highlight Background
      let bgClassName = 'btn-group-btn-bg radius';
      highlight === i &&
        (bgClassName = 'btn-group-btn-bg radius btn-group-btn-bg-focus');

      if (variant === 'bookmark') {
        bgClassName += ' half-radius';
      }
      tempBgs.push(<div key={i} className={bgClassName} />);
    }

    // Setter
    setButtons(tempBtns);
    setHighlightBgs(tempBgs);
  }, [
    titles,
    setButtons,
    highlight,
    setHighlight,
    setHighlightBgs,
    handleIndex,
    variant,
  ]);

  useEffect(() => {
    if (variant) {
      switch (variant) {
        case 'sm':
          setBtnGroupBox('btn-group-box-sm');
          setBtnGroup('btn-group-sm radius');
          break;
        default:
          break;
      }
    }
  }, []);

  return (
    <div className={btnGroupBox}>
      <div
        className={
          variant === 'bookmark'
            ? 'btn-group-2  radius half-radius'
            : 'btn-group-2 inset-shadow radius'
        }
      >
        {highlightBgs}
      </div>
      <div className={btnGroup}>{buttons}</div>
    </div>
  );
}

export default React.memo(ButtonGroup);
