import transformCalculation from '../transformCalculation';

const stampDutySA = (
  purchaseValue,
  foreign,
  firstHome,
  vacantLand,
  propertyType
) => {
  let result = 0;
  switch (true) {
    case purchaseValue <= 12000:
      purchaseValue = Math.ceil(purchaseValue / 100) * 100;
      result = transformCalculation(
        '1.0%*purchaseValue'.replace(/purchaseValue/g, purchaseValue)
      );
      break;
    case 12000 < purchaseValue && purchaseValue <= 30000:
      purchaseValue = Math.ceil(purchaseValue / 100) * 100;
      result = transformCalculation(
        '120+2.0%*(purchaseValue-12000)'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    case 30000 < purchaseValue && purchaseValue <= 50000:
      purchaseValue = Math.ceil(purchaseValue / 100) * 100;
      result = transformCalculation(
        '480+3.0%*(purchaseValue-30000)'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    case 50000 < purchaseValue && purchaseValue <= 100000:
      purchaseValue = Math.ceil(purchaseValue / 100) * 100;
      result = transformCalculation(
        '1080+3.5%*(purchaseValue-50000)'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    case 100000 < purchaseValue && purchaseValue <= 200000:
      purchaseValue = Math.ceil(purchaseValue / 100) * 100;
      result = transformCalculation(
        '2830+4.0%*(purchaseValue-100000)'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    case 200000 < purchaseValue && purchaseValue <= 250000:
      purchaseValue = Math.ceil(purchaseValue / 100) * 100;
      result = transformCalculation(
        '6830+4.25%*(purchaseValue-200000)'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    case 250000 < purchaseValue && purchaseValue <= 300000:
      purchaseValue = Math.ceil(purchaseValue / 100) * 100;
      result = transformCalculation(
        '8955+4.75%*(purchaseValue-250000)'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    case 300000 < purchaseValue && purchaseValue <= 500000:
      purchaseValue = Math.ceil(purchaseValue / 100) * 100;
      result = transformCalculation(
        '11330+5%*(purchaseValue-300000)'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    case 500000 < purchaseValue:
      purchaseValue = Math.ceil(purchaseValue / 100) * 100;
      result = transformCalculation(
        '21330+5.5%*(purchaseValue-500000)'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    default:
      break;
  }

  if (foreign === 1) {
    result += purchaseValue * 0.07;
  }
  if (result <= 0) {
    result = 0;
  }

  let mortgage = 170;
  let transferFee = 0;
  switch (true) {
    case purchaseValue <= 5000:
      transferFee = 170;
      break;
    case 5000 < purchaseValue && purchaseValue <= 20000:
      transferFee = 190;
      break;
    case 20000 < purchaseValue && purchaseValue <= 40000:
      transferFee = 208;
      break;
    case 40000 < purchaseValue && purchaseValue <= 50000:
      transferFee = 293;
      break;
    default:
      transferFee = 293 + 86.5 * Math.ceil((purchaseValue - 50000) / 10000);
      break;
  }

  let outcome = {
    result: result,
    mortgage: mortgage,
    transferFee: transferFee,
    total: result + mortgage + transferFee,
  };

  return outcome;
};

export default stampDutySA;
