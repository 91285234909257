import transformCalculation from '../transformCalculation';

const stampDutyTAS = (
  purchaseValue,
  foreign,
  firstHome,
  vacantLand,
  propertyType
) => {
  let originalValue = purchaseValue;
  let result = 0;
  switch (true) {
    case purchaseValue <= 3000:
      purchaseValue = Math.ceil(purchaseValue / 100) * 100;
      result = 50;
      break;
    case 3000 < purchaseValue && purchaseValue <= 25000:
      purchaseValue = Math.ceil(purchaseValue / 100) * 100;
      result = transformCalculation(
        '50+1.75%*(purchaseValue-3000)'.replace(/purchaseValue/g, purchaseValue)
      );
      break;
    case 25000 < purchaseValue && purchaseValue <= 75000:
      purchaseValue = Math.ceil(purchaseValue / 100) * 100;
      result = transformCalculation(
        '435+2.25%*(purchaseValue-25000)'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    case 75000 < purchaseValue && purchaseValue <= 200000:
      purchaseValue = Math.ceil(purchaseValue / 100) * 100;
      result = transformCalculation(
        '1560+3.5%*(purchaseValue-75000)'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    case 200000 < purchaseValue && purchaseValue <= 375000:
      purchaseValue = Math.ceil(purchaseValue / 100) * 100;
      result = transformCalculation(
        '5935+4.0%*(purchaseValue-200000)'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    case 375000 < purchaseValue && purchaseValue <= 725000:
      purchaseValue = Math.ceil(purchaseValue / 100) * 100;
      result = transformCalculation(
        '12935+4.25%*(purchaseValue-375000)'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    case 725000 < purchaseValue:
      purchaseValue = Math.ceil(purchaseValue / 100) * 100;
      result = transformCalculation(
        '27810+4.5%*(purchaseValue-725000)'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    default:
      break;
  }
  if (foreign === 1) {
    result += originalValue * 0.08;
  } else if (firstHome === 1 && propertyType === 0) {
    if (vacantLand === 0) {
      if (purchaseValue <= 400000) {
        result = result / 2;
      }
    }
  }
  if (result <= 0) {
    result = 0;
  }

  let mortgage = 138.51;
  let transferFee = 212.22;

  let outcome = {
    result: result,
    mortgage: mortgage,
    transferFee: transferFee,
    total: result + mortgage + transferFee,
  };

  return outcome;
};

export default stampDutyTAS;
