import React, { useState, useEffect, useCallback, Fragment } from 'react';
import CalculatorNav from '../../components/CalculatorNav';
import CalculatorBox from '../../components/CalculatorBox';
import CalculatorBoxRow from '../../components/CalculatorBoxRow';
import HalfBox from '../../components/HalfBox';
import WhiteBg from '../../components/WhiteBg';
import InputRow from '../../components/InputRow';
import ResultRow from '../../components/ResultRow';
import ButtonGroup from '../../components/ButtonGroup';
import carLoan from '../../util/car-loan/car-loan';
import { Typography } from 'antd';

function CarLoan({ fb }) {
  /* --------------------------------- Static --------------------------------- */

  const { Title } = Typography;
  const carLoanText =
    '(loanAmount*(1+interestRate/frequency)^(loanTerm*frequency)-residualAmount)/(((1+(interestRate/frequency))^(loanTerm*frequency)-1)/(interestRate/frequency))';
  const KEY1 = 11;
  const KEY2 = 22;
  const KEY3 = 33;
  const KEY4 = 44;
  const TITLE1 = 'Loan Amount';
  const TITLE2 = 'Interest Rate';
  const TITLE3 = 'Loan Term';
  const MONEY = 'money';
  const RATE = 'rate';
  const YEAR = 'year';
  const [TYPES] = useState(['Balloon', 'Residual']);
  const RESTITLE1 = 'Weekly (AUD)';
  const RESTITLE2 = 'Fortnightly (AUD)';
  const RESTITLE3 = 'Monthly (AUD)';
  const RESTITLE4 = 'Residual Amount';
  const [RANGE1] = useState([1, 1000000]);
  const [RANGE2] = useState([0, 100]);
  const [RANGE3] = useState([0, 15]);
  const SM = 'sm';

  /* --------------------------------- States -----------------å---------------- */

  const [loanAmount, setLoanAmount] = useState(50000);
  const [interestRate, setInterestRate] = useState(0.05);
  const [loanTerm, setLoanTerm] = useState(5);
  const [balloon, setBalloon] = useState(0.3);
  const [residualAmount, setResidualAmount] = useState(15000);
  const [rnb, setRnb] = useState(0);
  const [weekly, setWeekly] = useState(0);
  const [fortnightly, setFortnightly] = useState(0);
  const [monthly, setMonthly] = useState(0);

  /* -------------------------------- Functions ------------------------------- */

  const handleLoanAmount = useCallback(
    (newValue) => {
      setLoanAmount(newValue);
    },
    [setLoanAmount]
  );

  const handleInterestRate = useCallback(
    (newValue) => {
      setInterestRate(newValue);
    },
    [setInterestRate]
  );

  const handleLoanTerm = useCallback(
    (newValue) => {
      setLoanTerm(newValue);
    },
    [setLoanTerm]
  );

  const handleBalloon = useCallback(
    (newValue) => {
      setBalloon(newValue);
    },
    [setBalloon]
  );

  const handleResidualAmount = useCallback(
    (newValue) => {
      setResidualAmount(newValue);
    },
    [setResidualAmount]
  );

  const handleRnB = useCallback(
    (newValue) => {
      setRnb(newValue);
    },
    [setRnb]
  );

  /* ------------------------------- Calculation ------------------------------ */

  useEffect(() => {
    let tempWeekly = 0;
    let tempFortnightly = 0;
    let tempMonthly = 0;
    tempWeekly = carLoan(
      carLoanText,
      loanAmount,
      residualAmount,
      interestRate,
      loanTerm,
      52
    );
    tempFortnightly = carLoan(
      carLoanText,
      loanAmount,
      residualAmount,
      interestRate,
      loanTerm,
      26
    );
    tempMonthly = carLoan(
      carLoanText,
      loanAmount,
      residualAmount,
      interestRate,
      loanTerm,
      12
    );
    setWeekly(tempWeekly && tempWeekly !== Infinity ? tempWeekly : 0);
    setFortnightly(
      tempFortnightly && tempFortnightly !== Infinity ? tempFortnightly : 0
    );
    setMonthly(tempMonthly && tempMonthly !== Infinity ? tempMonthly : 0);
  }, [loanAmount, interestRate, loanTerm, rnb, residualAmount]);

  useEffect(() => {
    rnb === 0
      ? setResidualAmount(loanAmount * balloon)
      : setBalloon(residualAmount / loanAmount);
  }, [loanAmount, balloon, residualAmount, interestRate, loanTerm, rnb]);

  useEffect(() => {
    fb.analytics().logEvent('Car Loan Repayment Loaded');
    console.log('Car Loan Repayment Loaded');
  }, [fb]);

  return (
    <Fragment>
      <div className='landing-bg-dark-blue'>
        <div className='landing-circle'></div>
        <div className='landing-bg-mask'></div>
      </div>
      <CalculatorNav />
      <CalculatorBox>
        <CalculatorBoxRow>
          <HalfBox>
            <Title level={3}>Car Loan Repayment</Title>
            <WhiteBg>
              <InputRow
                key={KEY1}
                title={TITLE1}
                type={MONEY}
                defaultValue={loanAmount}
                handleValue={handleLoanAmount}
                range={RANGE1}
              />
              <InputRow
                key={KEY2}
                title={TITLE2}
                type={RATE}
                defaultValue={interestRate}
                handleValue={handleInterestRate}
                range={RANGE2}
              />
              <InputRow
                key={KEY3}
                title={TITLE3}
                type={YEAR}
                defaultValue={loanTerm}
                handleValue={handleLoanTerm}
                range={RANGE3}
              />
              <InputRow
                key={KEY4}
                title={
                  <ButtonGroup
                    titles={TYPES}
                    handleIndex={handleRnB}
                    variant={SM}
                  />
                }
                type={rnb === 0 ? RATE : MONEY}
                defaultValue={rnb === 0 ? balloon : residualAmount}
                handleValue={rnb === 0 ? handleBalloon : handleResidualAmount}
                range={rnb === 0 ? [0, 50] : [0, loanAmount / 2]}
                index={rnb}
              />
            </WhiteBg>
          </HalfBox>
          <HalfBox>
            <Title level={3}>Results</Title>
            <WhiteBg>
              <ResultRow key={KEY1} title={RESTITLE1} value={weekly} />
              <ResultRow key={KEY2} title={RESTITLE2} value={fortnightly} />
              <ResultRow key={KEY3} title={RESTITLE3} value={monthly} />
              <ResultRow key={KEY4} title={RESTITLE4} value={residualAmount} />
            </WhiteBg>
          </HalfBox>
        </CalculatorBoxRow>
      </CalculatorBox>
    </Fragment>
  );
}

export default CarLoan;
