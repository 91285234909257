import React from 'react';
import { Table } from 'antd';

function TutubeTable({ table }) {
  const columns = [
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
    },
    {
      title: 'Interest',
      dataIndex: 'Interest',
      key: 'Interest',
    },
    {
      title: 'Principal',
      dataIndex: 'Principal',
      key: 'Principal',
    },
    {
      title: 'Balance',
      dataIndex: 'Balance',
      key: 'Balance',
    },
  ];
  return (
    <div>
      <Table
        columns={columns}
        dataSource={table}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onMouseEnter: (event) => {}, // mouse enter row
          };
        }}
      />
    </div>
  );
}

export default TutubeTable;
