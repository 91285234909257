import React from 'react';
import { Button } from 'antd';

function Header() {
  return (
    <div className='header-bar'>
      <div className='header-title'>MORTGAGE CALCULATOR AU</div>
      <Button className='header-contact' href='mailto: developer@tutube.com.au'>
        CONTACT US
      </Button>
    </div>
  );
}

export default Header;
