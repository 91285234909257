import React, { useState, useEffect, useCallback, Fragment } from 'react';
import CalculatorNav from '../../components/CalculatorNav';
import CalculatorBox from '../../components/CalculatorBox';
import CalculatorBoxRow from '../../components/CalculatorBoxRow';
import HalfBox from '../../components/HalfBox';
import FullBox from '../../components/FullBox';
import WhiteBg from '../../components/WhiteBg';
import InputRow from '../../components/InputRow';
import ResultRow from '../../components/ResultRow';
import ButtonGroup from '../../components/ButtonGroup';
import AreaChart from '../../components/AreaChart';
import homeLoanPrincipalPlusInt from '../../util/home-loan/homeLoanPrincipalPlusInt';
import homeLoanInterestOnly from '../../util/home-loan/homeLoanInterestOnly';
import homeLoanRemainingPrincipal from '../../util/home-loan/homeLoanRemainingPrincipal';
import { Typography } from 'antd';

function HomeLoan({ fb }) {
  /* --------------------------------- Static --------------------------------- */

  const { Title } = Typography;
  const principalPlusInt =
    'loanAmount/(((1+(interestRate/frequency))^(loanTerm*frequency)-1)/((interestRate/frequency)*(1+(interestRate/frequency))^(loanTerm*frequency)))';
  const interestOnly = 'loanAmount*interestRate/frequency';
  const remainingPrincipal =
    '(loanAmount*(1+(interestRate/12))^(year*12))-(repayment*((((1+(interestRate/12))^(year*12))-1)/(interestRate/12)))';
  const KEY1 = 11;
  const KEY2 = 22;
  const KEY3 = 33;
  const TITLE1 = 'Loan Amount';
  const TITLE2 = 'Interest Rate';
  const TITLE3 = 'Loan Term';
  const MONEY = 'money';
  const RATE = 'rate';
  const YEAR = 'year';
  const DISCALIMER = 'homeloan';
  const [TITLES] = useState(['Principal + Interest', 'Interest Only']);
  const RESTITLE1 = 'Weekly (AUD)';
  const RESTITLE2 = 'Fortnightly (AUD)';
  const RESTITLE3 = 'Monthly (AUD)';
  const [RANGE1] = useState([1, 1000000000]);
  const [RANGE2] = useState([0, 100]);
  const [RANGE3] = useState([0, 50]);
  const LEGENDS = ['Total Balance', 'Loan Principal'];

  /* --------------------------------- States --------------------------------- */

  const [loanAmount, setLoanAmount] = useState(1000000);
  const [interestRate, setInterestRate] = useState(0.04);
  const [loanTerm, setLoanTerm] = useState(30);
  const [index, setIndex] = useState(0);
  const [weekly, setWeekly] = useState(0);
  const [fortnightly, setFortnightly] = useState(0);
  const [monthly, setMonthly] = useState(0);
  const [data, setData] = useState([]);
  const [max, setMax] = useState({ maxAmount: 0, maxYear: 0 });
  // const [fb, setFb] = useState(null);

  /* -------------------------------- Functions ------------------------------- */

  const handleIndex = useCallback(
    (newIndex) => {
      setIndex(newIndex);
    },
    [setIndex]
  );

  const handleLoanAmount = useCallback(
    (newValue) => {
      setLoanAmount(newValue);
    },
    [setLoanAmount]
  );

  const handleInterestRate = useCallback(
    (newValue) => {
      setInterestRate(newValue);
    },
    [setInterestRate]
  );

  const handleLoanTerm = useCallback(
    (newValue) => {
      setLoanTerm(newValue);
    },
    [setLoanTerm]
  );

  /* ------------------------------- Calculation ------------------------------ */

  useEffect(() => {
    let tempWeekly = 0;
    let tempFortnightly = 0;
    let tempMonthly = 0;
    if (index === 0) {
      tempWeekly = homeLoanPrincipalPlusInt(
        principalPlusInt,
        loanAmount,
        interestRate,
        loanTerm,
        52
      );
      tempFortnightly = homeLoanPrincipalPlusInt(
        principalPlusInt,
        loanAmount,
        interestRate,
        loanTerm,
        26
      );
      tempMonthly = homeLoanPrincipalPlusInt(
        principalPlusInt,
        loanAmount,
        interestRate,
        loanTerm,
        12
      );
    } else {
      tempWeekly = homeLoanInterestOnly(
        interestOnly,
        loanAmount,
        interestRate,
        52
      );
      tempFortnightly = homeLoanInterestOnly(
        interestOnly,
        loanAmount,
        interestRate,
        26
      );
      tempMonthly = homeLoanInterestOnly(
        interestOnly,
        loanAmount,
        interestRate,
        12
      );
    }
    setWeekly(tempWeekly && tempWeekly !== Infinity ? tempWeekly : 0);
    setFortnightly(
      tempFortnightly && tempFortnightly !== Infinity ? tempFortnightly : 0
    );
    setMonthly(tempMonthly && tempMonthly !== Infinity ? tempMonthly : 0);
  }, [loanAmount, interestRate, loanTerm, index]);

  useEffect(() => {
    /* ------------------------------- Area Chart ------------------------------- */

    const data = [];
    // console.log(loanAmount > 0 && interestRate > 0 && loanTerm > 0);
    if (loanAmount > 0 && interestRate > 0 && loanTerm > 0) {
      const repayment = homeLoanPrincipalPlusInt(
        principalPlusInt,
        loanAmount,
        interestRate,
        loanTerm,
        12
      );
      for (let year = 0; year <= loanTerm; year++) {
        let amount1 = homeLoanRemainingPrincipal(
          remainingPrincipal,
          loanAmount,
          interestRate,
          year,
          repayment
        );
        let amount2 = repayment * 12 * year;
        data.push({
          year: year,
          amount: amount1,
          type: LEGENDS[1],
        });
        data.push({
          year: loanTerm - year,
          amount: amount2,
          type: LEGENDS[0],
        });
      }
      setMax({ maxAmount: repayment * 12 * loanTerm, maxYear: loanTerm });
    }
    setData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanAmount, interestRate, loanTerm]);

  useEffect(() => {
    fb.analytics().logEvent('Home Loan Repayment Loaded');
    console.log('Home Loan Repayment Loaded');
  }, [fb]);

  return (
    <Fragment>
      <div className='landing-bg-dark-blue'>
        <div className='landing-circle'></div>
        <div className='landing-bg-mask'></div>
      </div>
      <CalculatorNav />
      <CalculatorBox disclaimer={DISCALIMER}>
        <CalculatorBoxRow>
          <HalfBox>
            <Title level={3}>Home Loan Repayment</Title>
            <WhiteBg>
              <InputRow
                key={KEY1}
                title={TITLE1}
                type={MONEY}
                defaultValue={loanAmount}
                handleValue={handleLoanAmount}
                range={RANGE1}
              />
              <InputRow
                key={KEY2}
                title={TITLE2}
                type={RATE}
                defaultValue={interestRate}
                handleValue={handleInterestRate}
                range={RANGE2}
              />
              <InputRow
                key={KEY3}
                title={TITLE3}
                type={YEAR}
                defaultValue={loanTerm}
                handleValue={handleLoanTerm}
                range={RANGE3}
              />
            </WhiteBg>
          </HalfBox>
          <HalfBox>
            <Title level={3}>Results</Title>
            <WhiteBg>
              <ResultRow key={KEY1} title={RESTITLE1} value={weekly} />
              <ResultRow key={KEY2} title={RESTITLE2} value={fortnightly} />
              <ResultRow key={KEY3} title={RESTITLE3} value={monthly} />
            </WhiteBg>
            <ButtonGroup titles={TITLES} handleIndex={handleIndex} />
          </HalfBox>
        </CalculatorBoxRow>
        <CalculatorBoxRow>
          <FullBox>
            <WhiteBg>
              <AreaChart data={data} max={max} legends={LEGENDS} />
            </WhiteBg>
          </FullBox>
        </CalculatorBoxRow>
      </CalculatorBox>
    </Fragment>
  );
}

export default HomeLoan;
