import transformCalculation from '../transformCalculation';

const stampDutyACT = (
  purchaseValue,
  firstHome,
  vacantLand,
  propertyType,
  children,
  income
) => {
  let result = 0;
  let reservedValue = Math.ceil(purchaseValue / 100) * 100;
  switch (true) {
    case purchaseValue <= 1600:
      result = 20;
      break;
    case 1601 <= purchaseValue && purchaseValue <= 200000:
      result = transformCalculation(
        '1.2%*purchaseValue'.replace(/purchaseValue/g, reservedValue)
      );
      break;
    case 200001 <= purchaseValue && purchaseValue <= 300000:
      result = transformCalculation(
        '2400+2.2%*(purchaseValue-200000)'.replace(
          /purchaseValue/g,
          reservedValue
        )
      );
      break;
    case 300001 <= purchaseValue && purchaseValue <= 500000:
      result = transformCalculation(
        '4600+3.4%*(purchaseValue-300000)'.replace(
          /purchaseValue/g,
          reservedValue
        )
      );
      break;
    case 500001 <= purchaseValue && purchaseValue <= 750000:
      result = transformCalculation(
        '11400+4.32%*(purchaseValue-500000)'.replace(
          /purchaseValue/g,
          reservedValue
        )
      );
      break;
    case 750001 <= purchaseValue && purchaseValue <= 1000000:
      result = transformCalculation(
        '22200+5.9%*(purchaseValue-750000)'.replace(
          /purchaseValue/g,
          reservedValue
        )
      );
      break;
    case 1000001 <= purchaseValue && purchaseValue <= 1454999:
      result = transformCalculation(
        '36950+6.4%*(purchaseValue-1000000)'.replace(
          /purchaseValue/g,
          reservedValue
        )
      );
      break;
    case 1455000 <= purchaseValue:
      result = transformCalculation(
        '4.54%*purchaseValue'.replace(/purchaseValue/g, reservedValue)
      );
      break;
    default:
      break;
  }
  // 目前是叠加优惠
  if (firstHome === 1 && propertyType === 0) {
    let threshold =
      (children === 0 && income <= 160000) ||
      (children === 1 && income <= 163330) ||
      (children === 2 && income <= 166660) ||
      (children === 3 && income <= 169990) ||
      (children === 4 && income <= 173320) ||
      (children >= 5 && income <= 176650);
    if (threshold) {
      result = 0;
    }
  }
  if (result <= 0) {
    result = 0;
  }

  let mortgage = 153;
  let transferFee = 409;

  let outcome = {
    result: result,
    mortgage: mortgage,
    transferFee: transferFee,
    total: result + mortgage + transferFee
  };

  return outcome;
};

export default stampDutyACT;
