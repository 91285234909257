const ExcelFormulas = {
  PMT: function (rate, nper, pv, fv, type) {
    if (!fv) fv = 0;
    if (!type) type = 0;

    if (rate == 0) return -(pv + fv) / nper;

    var pvif = Math.pow(1 + rate, nper);
    var pmt = (rate / (pvif - 1)) * -(pv * pvif + fv);

    if (type == 1) {
      pmt /= 1 + rate;
    }

    return pmt;
  },

  IPMT: function (pv, pmt, rate, per) {
    var tmp = Math.pow(1 + rate, per);
    return 0 - (pv * tmp * rate + pmt * (tmp - 1));
  },

  PPMT: function (rate, per, nper, pv, fv, type) {
    if (per < 1 || per >= nper + 1) return null;
    var pmt = this.PMT(rate, nper, pv, fv, type);
    var ipmt = this.IPMT(pv, pmt, rate, per - 1);
    return pmt - ipmt;
  },
};

const as = (
  originalPrincipal,
  interestRate,
  loanTerm,
  balloon,
  month,
  year
) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const rate = interestRate / 12;
  const nper = loanTerm * 12;
  const pv = -originalPrincipal;
  const fv = originalPrincipal * balloon;
  let balance =
    ExcelFormulas.PMT(rate, nper, pv, fv).toFixed(2) * 12 * loanTerm + fv;
  const table = [];
  let subInt = 0;
  let subPrin = 0;
  for (let per = 1; per <= nper; per++) {
    // PMT: function(rate, nper, pv, fv, type)
    let pmt = ExcelFormulas.PMT(rate, nper, pv, fv).toFixed(2);
    // IPMT: function(pv, pmt, rate, per)
    let ipmt = ExcelFormulas.IPMT(pv, pmt, rate, per - 1).toFixed(2);
    // PPMT: function (rate, per, nper, pv, fv, type)
    let ppmt = ExcelFormulas.PPMT(rate, per, nper, pv, fv).toFixed(2);
    balance = (balance -= pmt).toFixed(2);
    subInt += parseFloat(ipmt);
    subPrin += parseFloat(ppmt);
    let row = {
      key: per,
      Date: `${months[(month + per - 1) % 12].slice(0, 3)} ${
        parseInt(year) + parseInt((month + per - 1) / 12)
      }`,
      Interest: '$' + ipmt,
      Principal: '$' + ppmt,
      Balance: parseFloat(balance) > 0 ? '$' + balance : '$' + 0,
    };
    table.push(row);
    if ((month + per - 1) % 12 === 5) {
      let subTotal = {
        key: per,
        Date: 'Sub Totals',
        Interest: '$' + subInt.toFixed(2),
        Principal: '$' + subPrin.toFixed(2),
        Balance: '',
      };
      table.push(subTotal);
      subInt = 0;
      subPrin = 0;
    }
  }
  return { table: table, payment: ExcelFormulas.PMT(rate, nper, pv, fv) };
};

export default as;
