import transformCalculation from '../transformCalculation';

const stampDutyNT = (
  purchaseValue,
  foreign,
  firstHome,
  vacantLand,
  propertyType
) => {
  let result = 0;
  switch (true) {
    case purchaseValue <= 525000:
      result = transformCalculation(
        '6.571441%*(purchaseValue/1000)^2+15*(purchaseValue/1000)'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    case 525000 < purchaseValue && purchaseValue < 3000000:
      result = transformCalculation(
        '4.95%*purchaseValue'.replace(/purchaseValue/g, purchaseValue)
      );
      break;
    case 3000000 <= purchaseValue && purchaseValue < 5000000:
      result = transformCalculation(
        '5.75%*purchaseValue'.replace(/purchaseValue/g, purchaseValue)
      );
      break;
    case 5000000 <= purchaseValue:
      result = transformCalculation(
        '5.95%*purchaseValue'.replace(/purchaseValue/g, purchaseValue)
      );
      break;
    default:
      break;
  }
  if (firstHome === 1 && propertyType === 0) {
    if (purchaseValue <= 650000) {
      result = result - 18601;
    }
  }
  if (result <= 0) {
    result = 0;
  }

  let mortgage = 149;
  let transferFee = 149;

  let outcome = {
    result: result,
    mortgage: mortgage,
    transferFee: transferFee,
    total: result + mortgage + transferFee,
  };

  return outcome;
};

export default stampDutyNT;
