import transformCalculation from '../transformCalculation';

const homeLoanInterestOnly = (text, loanAmount, interestRate, frequency) => {
  let newText = text
    .replace(/loanAmount/g, loanAmount)
    .replace(/interestRate/g, interestRate)
    .replace(/frequency/g, frequency);
  const result = transformCalculation(newText);
  return result;
};

export default homeLoanInterestOnly;
