import transformCalculation from '../transformCalculation';

const stampDutyVIC = (
  purchaseValue,
  foreign,
  firstHome,
  vacantLand,
  propertyType
) => {
  let result = 0;
  if (propertyType === 0 && purchaseValue > 130000 && purchaseValue <= 550000) {
    switch (true) {
      case 130000 < purchaseValue && purchaseValue <= 440000:
        result = transformCalculation(
          '2870+5%*(purchaseValue-130000)'.replace(
            /purchaseValue/g,
            purchaseValue
          )
        );
        break;
      case 440000 < purchaseValue && purchaseValue <= 550000:
        result = transformCalculation(
          '18370+6%*(purchaseValue-440000)'.replace(
            /purchaseValue/g,
            purchaseValue
          )
        );
        break;
      default:
        break;
    }
  } else {
    switch (true) {
      case 0 <= purchaseValue && purchaseValue <= 25000:
        result = transformCalculation(
          '1.4%*purchaseValue'.replace(/purchaseValue/g, purchaseValue)
        );
        break;
      case 25000 < purchaseValue && purchaseValue <= 130000:
        result = transformCalculation(
          '350+2.4%*(purchaseValue-25000)'.replace(
            /purchaseValue/g,
            purchaseValue
          )
        );
        break;
      case 130000 < purchaseValue && purchaseValue <= 960000:
        result = transformCalculation(
          '2870+6%*(purchaseValue-130000)'.replace(
            /purchaseValue/g,
            purchaseValue
          )
        );
        break;
      case 960000 < purchaseValue:
        result = transformCalculation(
          '5.5%*purchaseValue'.replace(/purchaseValue/g, purchaseValue)
        );
        break;
      default:
        break;
    }
  }
  if (foreign === 1) {
    result += purchaseValue * 0.08;
  } else if (firstHome === 1 && propertyType === 0) {
    if (vacantLand === 2) {
      if (purchaseValue <= 600000) {
        result = 0;
      } else if (600000 < purchaseValue && purchaseValue <= 750000) {
        result =
          result *
          transformCalculation(
            '(purchaseValue-600000)/150000'.replace(
              /purchaseValue/g,
              purchaseValue
            )
          );
        result = result;
      }
    } else {
      if (purchaseValue <= 600000) {
        result = 0;
      } else if (600000 < purchaseValue && purchaseValue <= 750000) {
        result =
          result *
          transformCalculation(
            '(purchaseValue-600000)/150000'.replace(
              /purchaseValue/g,
              purchaseValue
            )
          );
        result = result;
      }
    }
  }
  if (result <= 0) {
    result = 0;
  }

  let mortgage = 110.8;
  let transferFee = 89.5 + (2.34 * purchaseValue) / 1000;
  if (transferFee > 3600) transferFee = 3600;

  let outcome = {
    result: result,
    mortgage: mortgage,
    transferFee: transferFee,
    total: result + mortgage + transferFee,
  };

  return outcome;
};

export default stampDutyVIC;
