import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

function TutubeSelect({ handleValue, frequency }) {
  const [options, setOptions] = useState(null);
  const { Option } = Select;
  useEffect(() => {
    if (frequency) {
      setOptions([
        <Option key='0' value='0'>
          Weekly
        </Option>,
        <Option key='1' value='1'>
          Fortnightly
        </Option>,
        <Option key='2' value='2'>
          Monthly
        </Option>,
        <Option key='3' value='3'>
          Annually
        </Option>,
      ]);
    } else {
      setOptions([
        <Option key='0' value='0'>
          0
        </Option>,
        <Option key='1' value='1'>
          1
        </Option>,
        <Option key='2' value='2'>
          2
        </Option>,
        <Option key='3' value='3'>
          3
        </Option>,
        <Option key='4' value='4'>
          4
        </Option>,
        <Option key='5' value='5'>
          5+
        </Option>,
      ]);
    }
  }, []);
  return (
    <div className='tutube-input'>
      <Select
        defaultValue='0'
        style={{ width: '100%', fontSize: '16px' }}
        onChange={handleValue}
      >
        {options}
      </Select>
    </div>
  );
}

export default TutubeSelect;
