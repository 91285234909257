import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import TutubeInput from './TutubeInput';
import TutubeSelect from './TutubeSelect';
import TutubeDatePicker from './TutubeDatePicker';

function InputRow({
  title,
  type,
  defaultValue,
  handleValue,
  range,
  index,
  select,
  date,
  frequency,
}) {
  const [input, setInput] = useState(null);
  useEffect(() => {
    if (select) {
      setInput(
        <TutubeSelect handleValue={handleValue} frequency={frequency} />
      );
    } else if (date) {
      setInput(
        <TutubeDatePicker
          defaultValue={defaultValue}
          handleValue={handleValue}
        />
      );
    } else {
      setInput(
        <TutubeInput
          type={type}
          defaultValue={defaultValue}
          handleValue={handleValue}
          range={range}
          index={index}
        />
      );
    }
  }, [index, range]);
  const { Text } = Typography;
  return (
    <div className='row'>
      <Text className='item-title black'>{title}</Text>
      {input}
    </div>
  );
}

export default React.memo(InputRow);
