import transformCalculation from '../transformCalculation';

const stampDutyWA = (
  purchaseValue,
  foreign,
  firstHome,
  vacantLand,
  propertyType
) => {
  let result = 0;
  if (propertyType === 1 && vacantLand === 2) {
    switch (true) {
      case purchaseValue <= 80000:
        purchaseValue = Math.ceil(purchaseValue / 100) * 100;
        result = transformCalculation(
          '1.9%*purchaseValue'.replace(/purchaseValue/g, purchaseValue)
        );
        break;
      case 80000 < purchaseValue && purchaseValue <= 100000:
        purchaseValue = Math.ceil(purchaseValue / 100) * 100;
        result = transformCalculation(
          '1520+2.85%*(purchaseValue-80000)'.replace(
            /purchaseValue/g,
            purchaseValue
          )
        );
        break;
      case 100000 < purchaseValue && purchaseValue <= 250000:
        purchaseValue = Math.ceil(purchaseValue / 100) * 100;
        result = transformCalculation(
          '2090+3.8%*(purchaseValue-100000)'.replace(
            /purchaseValue/g,
            purchaseValue
          )
        );
        break;
      case 250000 < purchaseValue && purchaseValue <= 500000:
        purchaseValue = Math.ceil(purchaseValue / 100) * 100;
        result = transformCalculation(
          '7790+4.75%*(purchaseValue-250000)'.replace(
            /purchaseValue/g,
            purchaseValue
          )
        );
        break;
      case 500000 < purchaseValue:
        purchaseValue = Math.ceil(purchaseValue / 100) * 100;
        result = transformCalculation(
          '19665+5.15%*(purchaseValue-500000)'.replace(
            /purchaseValue/g,
            purchaseValue
          )
        );
        break;
      default:
        break;
    }
  } else {
    switch (true) {
      case purchaseValue <= 120000:
        purchaseValue = Math.ceil(purchaseValue / 100) * 100;
        result = transformCalculation(
          '1.9%*purchaseValue'.replace(/purchaseValue/g, purchaseValue)
        );
        break;
      case 120000 < purchaseValue && purchaseValue <= 150000:
        purchaseValue = Math.ceil(purchaseValue / 100) * 100;
        result = transformCalculation(
          '2280+2.85%*(purchaseValue-120000)'.replace(
            /purchaseValue/g,
            purchaseValue
          )
        );
        break;
      case 150000 < purchaseValue && purchaseValue <= 360000:
        purchaseValue = Math.ceil(purchaseValue / 100) * 100;
        result = transformCalculation(
          '3135+3.8%*(purchaseValue-150000)'.replace(
            /purchaseValue/g,
            purchaseValue
          )
        );
        break;
      case 360000 < purchaseValue && purchaseValue <= 725000:
        purchaseValue = Math.ceil(purchaseValue / 100) * 100;
        result = transformCalculation(
          '11115+4.75%*(purchaseValue-360000)'.replace(
            /purchaseValue/g,
            purchaseValue
          )
        );
        break;
      case 725000 < purchaseValue:
        purchaseValue = Math.ceil(purchaseValue / 100) * 100;
        result = transformCalculation(
          '28453+5.15%*(purchaseValue-725000)'.replace(
            /purchaseValue/g,
            purchaseValue
          )
        );
        break;
      default:
        break;
    }
  }
  if (foreign === 1) {
    result += purchaseValue * 0.07;
  } else if (firstHome === 1 && propertyType === 0) {
    if (vacantLand === 2) {
      if (purchaseValue <= 300000) {
        result = 0;
      } else if (300000 < purchaseValue && purchaseValue <= 400000) {
        result = transformCalculation(
          '13.01%*(purchaseValue-300000)'.replace(
            /purchaseValue/g,
            purchaseValue
          )
        );
      }
    } else {
      if (purchaseValue <= 430000) {
        result = 0;
      } else if (430000 < purchaseValue && purchaseValue <= 530000) {
        result = transformCalculation(
          '19.19%*(purchaseValue-430000)'.replace(
            /purchaseValue/g,
            purchaseValue
          )
        );
      }
    }
  }
  if (result <= 0) {
    result = 0;
  }

  let mortgage = 174.7;
  let transferFee = 0;
  switch (true) {
    case purchaseValue <= 85000:
      transferFee = 174.7;
      break;
    case 85000 < purchaseValue && purchaseValue <= 120000:
      transferFee = 184.7;
      break;
    case 120000 < purchaseValue && purchaseValue <= 200000:
      transferFee = 204.7;
      break;
    case 200000 < purchaseValue && purchaseValue <= 300000:
      transferFee = 224.7;
      break;
    case 300000 < purchaseValue && purchaseValue <= 400000:
      transferFee = 244.7;
      break;
    case 400000 < purchaseValue && purchaseValue <= 500000:
      transferFee = 264.7;
      break;
    case 500000 < purchaseValue && purchaseValue <= 600000:
      transferFee = 284.7;
      break;
    case 600000 < purchaseValue && purchaseValue <= 700000:
      transferFee = 304.7;
      break;
    case 700000 < purchaseValue && purchaseValue <= 800000:
      transferFee = 324.7;
      break;
    case 800000 < purchaseValue && purchaseValue <= 900000:
      transferFee = 344.7;
      break;
    case 900000 < purchaseValue && purchaseValue <= 1000000:
      transferFee = 364.7;
      break;
    case 1000000 < purchaseValue && purchaseValue <= 1100000:
      transferFee = 384.7;
      break;
    case 1100000 < purchaseValue && purchaseValue <= 1200000:
      transferFee = 404.7;
      break;
    case 1200000 < purchaseValue && purchaseValue <= 1300000:
      transferFee = 424.7;
      break;
    case 1300000 < purchaseValue && purchaseValue <= 1400000:
      transferFee = 444.7;
      break;
    case 1400000 < purchaseValue && purchaseValue <= 1500000:
      transferFee = 464.7;
      break;
    case 1500000 < purchaseValue && purchaseValue <= 1600000:
      transferFee = 484.7;
      break;
    case 1600000 < purchaseValue && purchaseValue <= 1700000:
      transferFee = 504.7;
      break;
    case 1700000 < purchaseValue && purchaseValue <= 1800000:
      transferFee = 524.7;
      break;
    case 1800000 < purchaseValue && purchaseValue <= 1900000:
      transferFee = 544.7;
      break;
    case 1900000 < purchaseValue && purchaseValue <= 2000000:
      transferFee = 564.7;
      break;
    default:
      transferFee = 564.7 + 20 * Math.ceil((purchaseValue - 2000000) / 100000);
      break;
  }

  let outcome = {
    result: result,
    mortgage: mortgage,
    transferFee: transferFee,
    total: result + mortgage + transferFee,
  };

  return outcome;
};

export default stampDutyWA;
