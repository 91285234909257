import React, { useEffect, useState } from 'react';
import { Input } from 'antd';

function TutubeInput({ type, defaultValue, handleValue, range, index }) {
  const [formatter] = useState(new Intl.NumberFormat());
  const [value, setValue] = useState(0);
  // handleValue(defaultValue);
  let prefix;
  let suffix;

  switch (type) {
    case 'money':
      prefix = '$';
      suffix = '';
      break;
    case 'rate':
      prefix = '';
      suffix = '%';
      break;
    case 'year':
      prefix = '';
      suffix = 'Years';
      break;
    default:
      break;
  }

  useEffect(() => {
    handleValue(defaultValue);
    switch (type) {
      case 'money':
        defaultValue ? setValue(formatter.format(defaultValue)) : setValue(0);
        break;
      case 'rate':
        defaultValue
          ? setValue(parseFloat(defaultValue * 100).toFixed(2))
          : setValue('0.00');
        break;
      default:
        defaultValue ? setValue(defaultValue) : setValue(0);
    }
  }, [index]);

  return (
    <div className='tutube-input'>
      <Input
        prefix={prefix}
        suffix={suffix}
        size='large'
        value={value}
        onFocus={() => setValue('')}
        onBlur={() => {
          switch (type) {
            case 'money':
              value ? setValue(formatter.format(value)) : setValue(0);
              break;
            case 'rate':
              value ? setValue(parseFloat(value).toFixed(2)) : setValue('0.00');
              break;
            default:
              value ? setValue(value) : setValue(0);
          }
          type === 'rate'
            ? handleValue(parseFloat(value / 100))
            : handleValue(parseFloat(value));
        }}
        onChange={(e) => {
          let value =
            type === 'rate' ? e.target.value : parseInt(e.target.value);
          if (isNaN(value)) {
            value = 0;
          } else {
            if (value < range[0]) {
              value = range[0];
            }
            if (value > range[1]) {
              value = range[1];
            }
          }
          setValue(value);
          type === 'rate'
            ? handleValue(parseFloat(value / 100))
            : handleValue(parseFloat(value));
        }}
      />
    </div>
  );
}

export default React.memo(TutubeInput);
