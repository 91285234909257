import React from 'react';

function CalculatorNavBtn({ title }) {
  return (
    <div className='calculator-nav-btn-box'>
      <div className='calculator-nav-btn-bg'>{title}</div>
      <div className='calculator-nav-btn'>{title}</div>
    </div>
  );
}

export default CalculatorNavBtn;
