import React, { useState, useEffect, useCallback, Fragment } from 'react';
import CalculatorNav from '../../components/CalculatorNav';
import CalculatorBox from '../../components/CalculatorBox';
import CalculatorBoxRow from '../../components/CalculatorBoxRow';
import HalfBox from '../../components/HalfBox';
import FullBox from '../../components/FullBox';
import InputRow from '../../components/InputRow';
import IncomeTable from '../../components/IncomeTable';
import incomeTax from '../../util/income-tax/income-tax';
import { Typography } from 'antd';

function Income({ fb }) {
  /* --------------------------------- States -----------------å---------------- */

  const [income, setIncome] = useState(1000);
  const [frequencyIncome, setFrequencyIncome] = useState(0);
  const [table, setTable] = useState(null);
  const [foreign] = useState(0);

  /* --------------------------------- Static --------------------------------- */

  const { Title } = Typography;
  const KEY1 = 11;
  const KEY2 = 22;
  const TITLE1 = 'Gross Income';
  const TITLE2 = 'Frequency';
  const MONEY = 'money';
  const DISCALIMER = 'incometax';
  const [RANGE1] = useState([1, 10000000]);
  const [formatter] = useState(
    new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' })
  );
  /* -------------------------------- Functions ------------------------------- */

  const handleIncome = useCallback(
    (newValue) => {
      setIncome(newValue);
    },
    [setIncome]
  );

  const handleFrequencyIncome = useCallback(
    (newIndex) => {
      setFrequencyIncome(parseInt(newIndex));
    },
    [setFrequencyIncome]
  );

  /* ------------------------------- Calculation ------------------------------ */

  useEffect(() => {
    let annualIncome = income;
    switch (frequencyIncome) {
      case 0:
        annualIncome = income * 52;
        break;
      case 1:
        annualIncome = income * 26;
        break;
      case 2:
        annualIncome = income * 12;
        break;
      default:
        break;
    }
    const frequencies = ['Weekly', 'Fortnightly', 'Monthly', 'Annually'];
    const table = [
      [
        {
          Name: 'Taxable Income',
          Weekly: '',
          Fortnightly: '',
          Monthly: '',
          Annually: '',
          key: 1,
        },
        {
          Name: 'Total Tax',
          Weekly: '',
          Fortnightly: '',
          Monthly: '',
          Annually: '',
          key: 2,
        },
        {
          Name: 'Superannuation',
          Weekly: '',
          Fortnightly: '',
          Monthly: '',
          Annually: '',
          key: 3,
        },
        {
          Name: 'Medical Levy',
          Weekly: '',
          Fortnightly: '',
          Monthly: '',
          Annually: '',
          key: 4,
        },
      ],
      ['Net Income'],
    ];
    for (let i in frequencies) {
      let it = incomeTax(annualIncome, parseInt(i), foreign);
      let tax = it['tax'];
      let netIncome = it['netIncome'];
      let superannuation = it['superannuation'];
      let medicalLevy = it['medicalLevy'];
      table[0][0][frequencies[i]] = formatter.format(netIncome + tax);
      table[0][1][frequencies[i]] = formatter.format(tax);
      table[0][2][frequencies[i]] = formatter.format(superannuation);
      table[0][3][frequencies[i]] = formatter.format(medicalLevy);
      table[1].push(formatter.format(netIncome));
    }
    console.log(table);
    setTable(table);
  }, [income, foreign, frequencyIncome]);

  useEffect(() => {
    fb.analytics().logEvent('Income Tax Loaded');
    console.log('Income Tax Loaded');
  }, [fb]);

  return (
    <Fragment>
      <div className='landing-bg-dark-blue'>
        <div className='landing-circle'></div>
        <div className='landing-bg-mask'></div>
      </div>
      <CalculatorNav />
      <CalculatorBox disclaimer={DISCALIMER}>
        <Title level={3}>Income Tax</Title>
        <CalculatorBoxRow>
          <div
            className='white-bg shadow radius m-bottom'
            style={{ width: '100%' }}
          >
            <div style={{ display: 'flex', marginBottom: '30px' }}>
              <HalfBox>
                <InputRow
                  key={KEY1}
                  title={TITLE1}
                  type={MONEY}
                  defaultValue={income}
                  handleValue={handleIncome}
                  range={RANGE1}
                />
              </HalfBox>
              <HalfBox>
                <InputRow
                  key={KEY2}
                  title={TITLE2}
                  defaultValue={frequencyIncome}
                  handleValue={handleFrequencyIncome}
                  select
                  frequency
                />
              </HalfBox>
            </div>
            <FullBox>
              <Title level={3}>Results</Title>
              <IncomeTable table={table} />
            </FullBox>
          </div>
        </CalculatorBoxRow>
      </CalculatorBox>
    </Fragment>
  );
}

export default Income;
