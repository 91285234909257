function transformCalculation(newText) {
  String.prototype.splice = function(start, newStr) {
    return this.slice(0, start) + newStr + this.slice(start);
  };
  for (let i = 0; i < newText.length; i++) {
    if (newText[i] === '^') {
      let leftI = i;
      const left = [];
      if (newText[leftI - 1] === ')') {
        do {
          leftI -= 1;
          if (newText[leftI] === ')') {
            left.push(')');
          } else if (newText[leftI] === '(') {
            left.shift();
          }
        } while (left.length > 0);
      } else {
        while (!isNaN(newText[leftI - 1]) && leftI > 0) {
          leftI -= 1;
        }
      }
      newText = newText.splice(leftI, 'Math.pow(');
      i += 9;
      newText = newText.replace(/\^/, ',');
      let rightI = i;
      const right = [];
      if (newText[rightI + 1] === '(') {
        do {
          rightI += 1;
          if (newText[rightI] === '(') {
            right.push('(');
          } else if (newText[rightI] === ')') {
            right.shift();
          }
        } while (right.length > 0);
      } else {
        while (!isNaN(newText[rightI + 1]) && rightI < newText.length) {
          rightI += 1;
        }
      }
      newText = newText.splice(rightI + 1, ')');
      i += 1;
    }
  }
  newText = newText.replace(/%/g, '/100');
  return eval(newText);
}

export default transformCalculation;
