const lmi = (propertyValue, loanAmount, purpose, stateToBeSelected) => {
  const rates = [
    [
      '0.5125000000',
      '0.5963636364',
      '0.8665909091',
      '0.8665909091',
      '0.8665909091',
      '0.9504545455',
      '0.9504545455',
      '0.9504545455',
    ],
    [
      '0.5125000000',
      '0.5963636364',
      '0.8665909091',
      '0.8665909091',
      '0.8665909091',
      '0.9504545455',
      '0.9504545455',
      '1.0436363636',
    ],
    [
      '0.6150000000',
      '0.7454545455',
      '1.0995454545',
      '1.0995454545',
      '1.0995454545',
      '1.2113636364',
      '1.2113636364',
      '1.2113636364',
    ],
    [
      '0.6802272727',
      '0.9225000000',
      '1.1927272727',
      '1.1927272727',
      '1.1927272727',
      '1.3138636364',
      '1.3138636364',
      '1.3138636364',
    ],
    [
      '0.7640909091',
      '0.9970454545',
      '1.3604545455',
      '1.3604545455',
      '1.3604545455',
      '1.5002272727',
      '1.5002272727',
      '1.5002272727',
    ],
    [
      '0.9318181818',
      '1.1554545455',
      '1.4909090909',
      '1.4909090909',
      '1.4909090909',
      '1.6400000000',
      '1.6400000000',
      '1.6400000000',
    ],
    [
      '0.9784090909',
      '1.2206818182',
      '1.6493181818',
      '1.6493181818',
      '1.6493181818',
      '1.8077272727',
      '1.8077272727',
      '1.8077272727',
    ],
    [
      '1.1088636364',
      '1.3231818182',
      '1.7611363636',
      '1.7611363636',
      '1.7611363636',
      '1.9381818182',
      '1.9381818182',
      '1.9381818182',
    ],
    [
      '1.2952272727',
      '1.6306818182',
      '2.2736363636',
      '2.2736363636',
      '2.2736363636',
      '2.5065909091',
      '2.5065909091',
      '2.5065909091',
    ],
    [
      '1.4909090909',
      '1.9288636364',
      '2.4879545455',
      '2.4879545455',
      '2.4879545455',
      '2.7302272727',
      '2.7302272727',
      '2.7302272727',
    ],
    [
      '2.0034090909',
      '2.5811363636',
      '3.5968181818',
      '3.5968181818',
      '3.5968181818',
      '3.9602272727',
      '3.9602272727',
      '3.9602272727',
    ],
    [
      '2.0034090909',
      '2.5811363636',
      '3.7459090909',
      '3.7459090909',
      '3.7459090909',
      '4.1186363636',
      '4.1186363636',
      '4.1186363636',
    ],
    [
      '2.3481818182',
      '2.9538636364',
      '4.0440909091',
      '4.0440909091',
      '4.0440909091',
      '4.4447727273',
      '4.4447727273',
      '4.4447727273',
    ],
    [
      '2.4227272727',
      '2.9818181818',
      '4.1745454545',
      '4.1745454545',
      '4.1745454545',
      '4.5938636364',
      '4.5938636364',
      '4.5938636364',
    ],
    [
      '2.6463636364',
      '3.3079545455',
      '4.3515909091',
      '4.3515909091',
      '4.3515909091',
      '4.7895454545',
      '4.7895454545',
      '4.7895454545',
    ],
  ];
  let LVR = loanAmount / propertyValue;
  let row = 0;
  let col = null;
  let upper = 0.81;
  let lower = 0.8;
  while (row < rates.length && col === null) {
    if (lower < LVR && LVR <= upper) {
      switch (true) {
        case 0 <= loanAmount && loanAmount <= 300000:
          col = 0;
          break;
        case 300000 <= loanAmount && loanAmount <= 500000:
          col = 1;
          break;
        case 500000 <= loanAmount && loanAmount <= 600000:
          col = 2;
          break;
        case 600000 <= loanAmount && loanAmount <= 750000:
          col = 3;
          break;
        case 750000 <= loanAmount && loanAmount <= 1000000:
          col = 4;
          break;
        case 1000000 <= loanAmount && loanAmount <= 1500000:
          col = 5;
          break;
        case 1500000 <= loanAmount && loanAmount <= 2000000:
          col = 6;
          break;
        case 2000000 <= loanAmount:
          col = 7;
          break;
        default:
          break;
      }
    } else {
      row += 1;
      lower = parseFloat((lower + 0.01).toPrecision(12));
      upper = parseFloat((upper + 0.01).toPrecision(12));
    }
  }
  let stampDuty = null;
  try {
    stampDuty = Number(((loanAmount * rates[row][col]) / 100).toFixed(2));
  } catch (error) {
    stampDuty = Number((0).toFixed(2));
  }
  if (purpose === 1) {
    stampDuty = Number((stampDuty * 1.15).toFixed(2));
  }
  let rate = 0;
  switch (stateToBeSelected) {
    case 0 || 2:
      rate = 0;
      break;
    case 1 || 5 || 6 || 7:
      rate = 0.0107317073171;
      break;
    case 4:
      rate = 0.0118048780488;
      break;
    case 3:
      rate = 0.096585365854;
      break;
    default:
      break;
  }
  let lmi = (stampDuty + stampDuty * rate).toFixed(0);
  let outcome = { stampDuty: stampDuty, lmi: lmi, LVR: LVR };
  return outcome;
};

export default lmi;
