import transformCalculation from '../transformCalculation';

const stampDutyNSW = (
  purchaseValue,
  foreign,
  firstHome,
  vacantLand,
  propertyType
) => {
  let result = 0;
  switch (true) {
    case 0 <= purchaseValue && purchaseValue <= 14000:
      result = transformCalculation(
        '1.25%*purchaseValue'.replace(/purchaseValue/g, purchaseValue)
      );
      break;
    case 14001 <= purchaseValue && purchaseValue <= 30000:
      result = transformCalculation(
        '175+1.5%*(Math.ceil(purchaseValue/100)/10-14)*1000'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    case 30001 <= purchaseValue && purchaseValue <= 81000:
      result = transformCalculation(
        '415+1.75%*(Math.ceil(purchaseValue/100)/10-30)*1000'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    case 81001 <= purchaseValue && purchaseValue <= 304000:
      result = transformCalculation(
        '1307+3.5%*(Math.ceil(purchaseValue/100)/10-81)*1000'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    case 304001 <= purchaseValue && purchaseValue <= 1013000:
      result = transformCalculation(
        '9112+4.5%*(Math.ceil(purchaseValue/100)/10-304)*1000'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    case 1013001 <= purchaseValue && purchaseValue <= 3040000:
      result = transformCalculation(
        '41017+5.5%*(Math.ceil(purchaseValue/100)/10-1013)*1000'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    case 3040001 <= purchaseValue:
      result = transformCalculation(
        '152502+7%*(Math.ceil(purchaseValue/100)/10-3040)*1000'.replace(
          /purchaseValue/g,
          purchaseValue
        )
      );
      break;
    default:
      break;
  }
  if (foreign === 1) {
    result += purchaseValue * 0.08;
  } else if (firstHome === 1 && propertyType === 0) {
    if (vacantLand === 2) {
      if (purchaseValue <= 350000) {
        result = 0;
      } else if (350000 < purchaseValue && purchaseValue <= 450000) {
        result = transformCalculation(
          'purchaseValue*0.1574-55090'.replace(/purchaseValue/g, purchaseValue)
        );
      }
    } else {
      if (purchaseValue <= 650000) {
        result = 0;
      } else if (650000 < purchaseValue && purchaseValue <= 800000) {
        result = transformCalculation(
          'purchaseValue*21%-136510'.replace(/purchaseValue/g, purchaseValue)
        );
      }
    }
  }
  if (result <= 0) {
    result = 0;
  }

  let mortgage = 143.5;
  let transferFee = 143.5;

  let outcome = {
    result: result,
    mortgage: mortgage,
    transferFee: transferFee,
    total: result + mortgage + transferFee,
  };

  return outcome;
};

export default stampDutyNSW;
