import React, { Fragment } from 'react';

function Footer() {
  return (
    <Fragment>
      <div className='download-title'>Download free today</div>
      <div className='footer-2'>
        <div className='footer-left'>
          <a href='https://apps.apple.com/au/app/mortgage-calculator-au/id1512133933'>
            <div className='apple'></div>
          </a>
          <a href='https://play.google.com/store/apps/details?id=com.tutube.www'>
            <div className='google' />
          </a>
        </div>
        <div className='disclaimer'>
          Disclaimer
          <div
            style={{
              marginLeft: '10px',
              fontWeight: '200',
              fontSize: '12px',
              textAlign: 'justify',
            }}
          >
            Whilst every effort has been made to ensure the accuracy of this
            calculator, the results should be used as an indication only. They
            are neither a quote nor a pre-qualification for any loan.
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Footer;
