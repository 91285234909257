import React from 'react';
import { Link } from 'react-router-dom';
import CalculatorNavBtn from './CalculatorNavBtn';

function CalculatorNav() {
  return (
    <div className='calculator-nav-box'>
      <div className='calculator-nav-inner-box'>
        <Link to='/'>
          <div className='calculator-nav-title'>MORTGAGE CALCULATOR AU</div>
        </Link>
        <Link to='/HomeLoanRepayment'>
          <CalculatorNavBtn title={'Home Loan'} />
        </Link>
        <Link to='/CarLoanRepayment'>
          <CalculatorNavBtn title={'Car Loan'} />
        </Link>
        <Link to='/AmortizationSchedule'>
          <CalculatorNavBtn title={'Amortization'} />
        </Link>
        <Link to='/IncomeTax'>
          <CalculatorNavBtn title={'Income Tax'} />
        </Link>
        <Link to='/StampDuty'>
          <CalculatorNavBtn title={'Stamp Duty'} />
        </Link>
        <Link to='/LMICalculator'>
          <CalculatorNavBtn title={'LMI'} />
        </Link>
      </div>
    </div>
  );
}

export default CalculatorNav;
